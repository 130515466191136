import React, {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {
	useGetPogisDocumentIdsLazyQuery,
	useGetPogisDocumentMetadataQuery,
	useGetPogisDocumentsLazyQuery,
} from './hooks/PogisDocuments.generated';
import {PogisDocumentsTooltipTranslationProvider} from './components/PogisDocumentTranslationTooltipProvider';
import {
	AttachmentRef,
	InputMaybe,
	PogisDate,
	PogisDateInput,
	PogisDocument,
	Scalars,
} from 'types';
import {useSearchParams} from 'react-router-dom';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {IDetailsList, IRefObject} from '@fluentui/react';
import {
	renderArrayField,
	renderAttachmentsPogis,
	renderDateString,
	renderLinkField,
	renderPogisDate,
} from 'components/EntityList/ColumnRenderers';
import {
	InfinityList,
	InfinityListColumn,
	InfinityListFilter,
	InfinityListURLParams,
} from 'components/InfinityList/InfinityList';
import {DEFAULT_DATE} from 'components/EntityList/EntityUtils';

interface DataStorePogisDocument {
	[key: string]: PogisDocumentPage;
}

export type PogisDocumentPage = {
	id: Scalars['String'];
	pogisId: Scalars['String'];
	keywords: Array<Scalars['String']>;
	markets: Array<Scalars['String']>;
	regulationStatus: Scalars['String'];
	shortInfo: Scalars['String'];
	effectiveDate?: InputMaybe<Scalars['DateTime']>;
	ntDate?: InputMaybe<Scalars['DateTime']>;
	nfDate?: InputMaybe<Scalars['DateTime']>;
	modelYear?: InputMaybe<Scalars['DateTime']>;
	otherDates?: Array<PogisDate>;
	standards?: InputMaybe<Array<Scalars['String']>>;
	associations?: InputMaybe<Array<Scalars['String']>>;
	relatedDocuments?: InputMaybe<Array<Scalars['String']>>;
	editor: Scalars['String'];
	docStatus: Scalars['String'];
	attachments?: InputMaybe<AttachmentRef[]>;
};

interface IMapPogisDocument {
	(pogisDocument: PogisDocument): PogisDocumentPage;
}
interface IFilters2MongoQuery {
	(allFilters: InfinityListFilter[]): string;
}
interface ILink2MongoQuery {
	(searchParams: URLSearchParams): string;
}

interface ILink2Filter {
	(searchParams: URLSearchParams): InfinityListFilter[];
}

const arrDateCols = [
	'Effective Date',
	'New Registration',
	'New Types',
	'Model Year',
];

const funcMapPogisDocument: IMapPogisDocument = pogisDocument => {
	const pogisdates = pogisDocument.dates;
	const effDates = pogisdates.filter(x => x.dateType === 'Effective Date');
	const nfDates = pogisdates.filter(x => x.dateType === 'New Registration');
	const ntDates = pogisdates.filter(x => x.dateType === 'New Types');
	const myDates = pogisdates.filter(x => x.dateType === 'Model Year');
	const restDates = pogisdates.filter(
		x => arrDateCols.indexOf(x.dateType) === -1,
	);
	const effDate = effDates.length > 0 ? effDates[0].date : DEFAULT_DATE;
	const nfDate = nfDates.length > 0 ? nfDates[0].date : DEFAULT_DATE;
	const ntDate = ntDates.length > 0 ? ntDates[0].date : DEFAULT_DATE;
	const myDate = myDates.length > 0 ? myDates[0].date : DEFAULT_DATE;

	const pogisDocMapped: PogisDocumentPage = {
		id: atob(pogisDocument.id).split('\n')[1].slice(1),
		pogisId: pogisDocument.pogisId,
		keywords: pogisDocument.keywords,
		markets: pogisDocument.markets,
		regulationStatus: pogisDocument.regulationStatus,
		shortInfo: pogisDocument.shortInfo,
		effectiveDate: effDate,
		ntDate: nfDate,
		nfDate: ntDate,
		modelYear: myDate,
		otherDates: restDates,
		standards: pogisDocument.standards,
		associations: pogisDocument.associations,
		relatedDocuments: pogisDocument.relatedDocuments,
		editor: pogisDocument.editor,
		docStatus: pogisDocument.docStatus,
		attachments: pogisDocument.pogisAttachments ?? [],
	};
	return pogisDocMapped;
};

const funcFilters2MongoQuery: IFilters2MongoQuery = allFilters => {
	const arrayFields = [
		'pogisId',
		'keywords',
		'markets',
		'standards',
		'regulationStatus',
		'editor',
	];

	const arrQueryStrings = arrayFields
		.filter(x1 => allFilters.filter(x2 => x2.columnKey === x1).length > 0)
		.map(
			x1 =>
				`${x1}:{$in:[${allFilters
					.filter(x2 => x2.columnKey === x1 && !x2.selection.includes(';'))
					.map(x2 => `'${x2.selection}'`)}]}`,
		);

	if (arrQueryStrings.length === 0) return '';
	return `{ ${arrQueryStrings.join(',')} }`;
};

const funcLink2MongoQuery: ILink2MongoQuery = searchParams => {
	const linkFields = [
		'id',
		'keywords',
		'markets',
		'standards',
		'regulationStatus',
		'editor',
	];
	const arrayFields = [
		'pogisId',
		'keywords',
		'markets',
		'standards',
		'regulationStatus',
		'editor',
	];
	const arrQueryStrings = linkFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.map(
			x1 =>
				`${arrayFields[linkFields.indexOf(x1)]}:{$in:[${searchParams
					.getAll(x1)
					.map(x2 => `'${x2}'`)}]}`,
		);

	if (arrQueryStrings.length === 0) return '';
	return `{ ${arrQueryStrings.join(',')} }`;
};

const funcLink2Filter: ILink2Filter = searchParams => {
	const linkFields = [
		'id',
		'keywords',
		'markets',
		'standards',
		'regulationStatus',
		'editor',
	];
	const arrayFields = [
		'pogisId',
		'keywords',
		'markets',
		'standards',
		'regulationStatus',
		'editor',
	];
	const result: InfinityListFilter[] = [];
	linkFields
		.filter(x1 => searchParams.getAll(x1).length > 0)
		.forEach(x1 => {
			searchParams.getAll(x1).forEach(x2 =>
				result.push({
					columnKey: arrayFields[linkFields.indexOf(x1)],
					selection: x2,
				}),
			);
		});
	return result;
};

const PogisDocumentPage: React.FC = () => {
	const {t} = useTranslation('features/pogisdocuments', {
		keyPrefix: 'PogisDocumentsList',
	});

	const [sortField, setSortField] = useState('PogisId');

	const [sortOrder, setSetOrder] = useState('ASC');

	const [lastFetchedId, setlastFetchedId] = useState('');

	const [cursor, setCursor] = useState(0);

	const [dsPogisDocument, setDSPogisDocument] =
		useState<DataStorePogisDocument>({});

	const [arrPogisDocument, setArrPogisDocument] = useState<PogisDocumentPage[]>(
		[],
	);

	const {loading: loadingMetaData, data: dataMetaData} =
		useGetPogisDocumentMetadataQuery();

	const [gePogisDocumentIds, {loading: loadingIds, data: dataIds}] =
		useGetPogisDocumentIdsLazyQuery();

	const [getPogisDocuments, {loading: loadingDocuments, data: dataDocuments}] =
		useGetPogisDocumentsLazyQuery();

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	const intBatchSize = 100;

	const queryLink = funcLink2MongoQuery(searchParams);

	const linksFilters = funcLink2Filter(searchParams);

	console.log(linksFilters);

	const firstRender = async () => {
		// *firstRender called only once at begin
		// 		*ids come before documents -> load first batch -> set lastFetchdId and cursor correctly
		// *ids come after documents  -> load first batch -> set lastFetchdId and cursor correctly
		const arrPogisDocumentNext: PogisDocumentPage[] = [];
		const dsPogisDocumentNext: DataStorePogisDocument = {};

		gePogisDocumentIds({
			variables: {query: queryLink, filteredIds: '', sortField, sortOrder},
		});

		const {data: dataDocumentsNext} = await getPogisDocuments({
			variables: {query: queryLink, filteredIds: '', sortField, sortOrder},
		});
		(dataDocumentsNext?.pogisDocuments ?? []).forEach(x => {
			arrPogisDocumentNext.push(funcMapPogisDocument(x as PogisDocument));
		});

		arrPogisDocumentNext.forEach(x => {
			dsPogisDocumentNext[x.id] = x;
		});

		const lastFetchdIdNext = arrPogisDocumentNext.at(-1)!.id;

		setDSPogisDocument(dsPogisDocumentNext);

		setArrPogisDocument(prevItems => [...prevItems, ...arrPogisDocumentNext]);

		setlastFetchedId(lastFetchdIdNext);

		setCursor(arrPogisDocumentNext.length);
	};

	const applyFilter = async (allFilters: InfinityListFilter[]) => {
		const dataURL: InfinityListURLParams = {};
		const linkFields = [
			'id',
			'keywords',
			'markets',
			'standards',
			'regulationStatus',
			'editor',
		];
		const arrayFields = [
			'pogisId',
			'keywords',
			'markets',
			'standards',
			'regulationStatus',
			'editor',
		];
		allFilters.forEach(x => {
			const linkField = linkFields[arrayFields.indexOf(x.columnKey)];
			if (dataURL[linkField] === undefined) {
				dataURL[linkField] = [x.selection];
			} else {
				dataURL[linkField].push(x.selection);
			}
		});

		navigate({
			pathname: '',
			search: createSearchParams(dataURL).toString(),
		});

		const queryFilter = funcFilters2MongoQuery(allFilters);

		const {data: dataDocumentsIdsNext} = await gePogisDocumentIds({
			variables: {
				query: queryFilter,
				filteredIds: '',
				sortField,
				sortOrder,
			},
		});

		const arrIds: string[] = (dataDocumentsIdsNext?.pogisDocumentIds ?? '')
			.split(';')
			.filter(x => x.trim() !== '');

		const arrIdsNext: string[] = arrIds.slice(0, intBatchSize);

		if (arrIdsNext.length > 0) {
			const lastFetchdIdNext = arrIdsNext.at(-1) ?? '';

			const arrIdsToFetch = arrIdsNext.filter(x => true);

			if (arrIdsToFetch.length > 0) {
				const strIdsToFetch = arrIdsToFetch.join(';');

				const {data: dataDocumentsNext} = await getPogisDocuments({
					variables: {
						query: '',
						filteredIds: strIdsToFetch,
						sortField,
						sortOrder,
					},
				});
				const dsPogisDocumentNext: DataStorePogisDocument = {};

				const arrPogisDocumentFetched = (
					dataDocumentsNext?.pogisDocuments ?? []
				).map(x => funcMapPogisDocument(x as PogisDocument));

				arrPogisDocumentFetched.forEach(x => {
					dsPogisDocumentNext[x.id] = x;
				});

				const arrPogisDocumentNext = arrIdsNext.map(
					x => dsPogisDocumentNext[x],
				);

				setDSPogisDocument({
					...dsPogisDocument,
					...dsPogisDocumentNext,
				});

				setArrPogisDocument(arrPogisDocumentNext);

				setlastFetchedId(lastFetchdIdNext);

				setCursor(arrIdsNext.length);
			} else {
				const arrPogisDocumentNext = arrIdsNext.map(x => dsPogisDocument[x]);

				setArrPogisDocument(arrPogisDocumentNext);

				setlastFetchedId(lastFetchdIdNext);

				setCursor(arrIdsNext.length);
			}
		} else {
			setArrPogisDocument([]);

			setlastFetchedId('');

			setCursor(0);
		}
	};

	const loadItemsNext = async (currentId: string) => {
		if (currentId === lastFetchedId) {
			// *currentId is equal to known lastFetchedId (last row has been mounted)
			// 		*ids have still not came -> do not fetch documents because of internet problems
			// 		*ids are known -> fetch next batch -> set lastFetchdId and cursor correctly
			// 		*filter or sort is changed -> this event is not fired

			const intEnd = cursor + intBatchSize;

			const arrIds: string[] = (dataIds?.pogisDocumentIds ?? '').split(';');

			const arrIdsNext: string[] = arrIds.slice(cursor, intEnd);
			if (arrIdsNext.length > 0) {
				const lastFetchdIdNext = arrIdsNext.at(-1) ?? '';

				const arrIdsToFetch = arrIdsNext.filter(x => true);

				if (arrIdsToFetch.length > 0) {
					const strIdsToFetch = arrIdsToFetch.join(';');

					const {data: dataDocumentsNext} = await getPogisDocuments({
						variables: {
							query: '',
							filteredIds: strIdsToFetch,
							sortField,
							sortOrder,
						},
					});

					const dsPogisDocumentNext: DataStorePogisDocument = {};

					const arrPogisDocumentFetched = (
						dataDocumentsNext?.pogisDocuments ?? []
					).map(x => funcMapPogisDocument(x as PogisDocument));

					arrPogisDocumentFetched.forEach(x => {
						dsPogisDocumentNext[x.id] = x;
					});

					const arrPogisDocumentNext = arrIdsNext.map(
						x => dsPogisDocumentNext[x],
					);

					setDSPogisDocument({
						...dsPogisDocument,
						...dsPogisDocumentNext,
					});

					setArrPogisDocument(prevItems => [
						...prevItems,
						...arrPogisDocumentNext,
					]);

					setlastFetchedId(lastFetchdIdNext);

					setCursor(intEnd);
				} else {
					const arrPogisDocumentNext = arrIdsNext.map(x => dsPogisDocument[x]);

					setArrPogisDocument(prevItems => [
						...prevItems,
						...arrPogisDocumentNext,
					]);

					setlastFetchedId(lastFetchdIdNext);

					setCursor(intEnd);
				}

				// console.log(
				//	'next fetch will be at: ' +
				//		(arrPogisDocumentNext.at(-1)?.pogisId ?? ''),
				// );
			}
		}
	};
	const columns: InfinityListColumn[] = useMemo(
		() => [
			{
				key: 'pogisId',
				name: t('POGISID'),
				fieldName: 'pogisId',
				sortable: true,
				minWidth: 60,
				maxWidth: 60,
				filterable: true,
				filterOnFilter: true,
				filterType: 'Array',
				fieldValuesUnique: (
					dataMetaData?.pogisDocumentsMetadata.pogisIdOptions ?? ''
				).split(';'),
			},
			{
				key: 'keywords',
				name: t('Keywords'),
				fieldName: 'keywords',
				minWidth: 200,
				maxWidth: 300,
				isResizable: true,
				isMultiline: true,
				filterable: true,
				filterOnFilter: true,
				filterType: 'Array',
				fieldValuesUnique: (
					dataMetaData?.pogisDocumentsMetadata.keywordOptions ?? ''
				).split(';;'),
				onRender: renderArrayField(),
			},
			{
				key: 'markets',
				name: t('Markets'),
				fieldName: 'markets',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterable: true,
				filterOnFilter: true,
				filterType: 'Array',
				fieldValuesUnique: (
					dataMetaData?.pogisDocumentsMetadata.marketOptions ?? ''
				).split(';;'),
				onRender: renderArrayField(),
			},
			{
				key: 'standards',
				name: t('Standards'),
				fieldName: 'standards',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				isMultiline: true,
				filterable: true,
				filterOnFilter: true,
				filterType: 'Array',
				fieldValuesUnique: (
					dataMetaData?.pogisDocumentsMetadata.standardOptions ?? ''
				).split(';;'),
				onRender: renderArrayField(),
			},
			{
				key: 'shortInfo',
				name: t('ShortInfo'),
				fieldName: 'shortInfo',
				minWidth: 400,
				maxWidth: 550,
				isResizable: true,
				isMultiline: true,
			},
			{
				key: 'attachments',
				name: t('Attachments'),
				fieldName: 'attachments',
				minWidth: 150,
				maxWidth: 200,
				isResizable: true,
				onRender: renderAttachmentsPogis(),
			},
			{
				key: 'effectiveDate',
				name: t('Effective Date'),
				fieldName: 'effectiveDate',
				minWidth: 150,
				maxWidth: 150,
				filterable: true,
				onRender: renderDateString(),
			},
			{
				key: 'ntDate',
				name: t('NTDate'),
				fieldName: 'ntDate',
				minWidth: 150,
				maxWidth: 150,
				filterable: true,
				onRender: renderDateString(),
			},
			{
				key: 'nfDate',
				name: t('NFDate'),
				fieldName: 'nfDate',
				minWidth: 150,
				maxWidth: 150,
				filterable: true,
				onRender: renderDateString(),
			},
			{
				key: 'modelYear',
				name: t('ModelYear'),
				fieldName: 'modelYear',
				minWidth: 100,
				maxWidth: 100,
				filterable: true,
				onRender(item: PogisDocumentPage) {
					return (
						<div>
							{item.modelYear.startsWith('1970')
								? ''
								: t('MY') +
								  new Date(item.modelYear).getFullYear().toString().slice(2, 4)}
						</div>
					);
				},
			},
			{
				key: 'otherDates',
				name: t('Dates'),
				fieldName: 'otherDates',
				minWidth: 150,
				maxWidth: 150,
				onRender: renderPogisDate(),
			},
			{
				key: 'regulationStatus',
				name: t('RegulationStatus'),
				fieldName: 'regulationStatus',
				minWidth: 150,
				maxWidth: 200,
				filterable: true,
				filterType: 'Array',
				fieldValuesUnique: (
					dataMetaData?.pogisDocumentsMetadata.regStatusOptions ?? ''
				).split(';'),
				isResizable: true,
			},
			{
				key: 'editor',
				name: t('Editor'),
				fieldName: 'editor',
				minWidth: 150,
				maxWidth: 250,
				isResizable: true,
				isMultiline: false,
				filterable: true,
				filterOnFilter: true,
				filterType: 'Array',
				fieldValuesUnique: (
					dataMetaData?.pogisDocumentsMetadata.editorOptions ?? ''
				).split(';'),
			},
			{
				key: 'associations',
				name: t('Associations'),
				fieldName: 'associations',
				minWidth: 150,
				maxWidth: 150,
				isMultiline: true,
				onRender: renderArrayField(),
			},
			{
				key: 'relatedDocuments',
				name: t('RelatedDocuments'),
				fieldName: 'relatedDocuments',
				isMultiline: true,
				minWidth: 150,
				maxWidth: 150,
				onRender: renderArrayField(),
			},
			{
				key: 'linkVersion',
				name: t('LinkVersion'),
				fieldName: 'linkVersion',
				minWidth: 200,
				maxWidth: 200,
				sortable: true,
				onRender: renderLinkField(),
			},
			{
				key: 'docStatus',
				name: t('DocStatus'),
				fieldName: 'docStatus',
				minWidth: 150,
				maxWidth: 150,
				isMultiline: false,
				sortable: true,
			},
		],
		[dataMetaData?.pogisDocumentsMetadata],
	);

	return (
		<div style={{marginLeft: 20, marginTop: 20}}>
			<div style={{marginLeft: 10}}>
				<b>{t('Archive')}</b>
			</div>
			<PogisDocumentsTooltipTranslationProvider>
				<InfinityList
					items={arrPogisDocument}
					sticky={true}
					columns={columns}
					getKey={(item: PogisDocument) => item.id}
					onShouldVirtualize={() => true}
					selectionMode={0}
					stillLoading={!(loadingIds || loadingDocuments || loadingMetaData)}
					initalFilters={linksFilters}
					firstRender={firstRender}
					loadItemsNext={loadItemsNext}
					applyFilter={applyFilter}
				/>
			</PogisDocumentsTooltipTranslationProvider>
		</div>
	);
};

export default PogisDocumentPage;
