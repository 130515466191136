import React, {useEffect} from 'react';
import {
	DirectionalHint,
	FontIcon,
	Link,
	Stack,
	TooltipDelay,
	TooltipHost,
	useTheme,
	Text,
	mergeStyles,
	Theme,
} from '@fluentui/react';
import {
	RegulatoryDocumentStatus,
	RequirementCategory,
	RequirementStatus,
	WorkflowStatus,
} from 'types';
import {
	LazyFieldRenderInput,
	renderArrayField,
	RequirementOfRequirementsField,
} from './ColumnRenderers';
import {TFunction, useTranslation} from 'react-i18next';
import {useRequirementsTranslation} from './RequirementsFields/useRequirementsTranslation';
import {RequirementsFieldName} from './RequirementsFields/RequirementsFieldName';
import {RichtextDisplay} from 'components/RichtextDisplay';
import {VexClustersField} from './RequirementsFields/VexClustersField';
import {isApprovalModified} from 'features/RegulatoryDocuments/regulatoryUtils';
import {LoadSpinner} from 'components/LoadWrapper/LoadSpinner';
import {useGetRequirementTooltipDetailsLazyQuery} from './hooks/useGetRequirementTooltipDetails.generated';

const StatusEnums = {
	...WorkflowStatus,
	...RegulatoryDocumentStatus,
	...RequirementStatus,
	...RequirementCategory,
};

export const RequirementsField: React.FC<LazyFieldRenderInput> = ({
	item,
	i,
	col,
	isLazy,
}) => {
	const theme = useTheme();
	const {t: tEnum} = useTranslation('common/enums', {
		keyPrefix: 'RequirementStatus',
	});
	const {t} = useRequirementsTranslation();

	const [getRequirementTooltipDetails, {data, loading}] =
		useGetRequirementTooltipDetailsLazyQuery();

	const [loadedReqs, setLoadedReqs] = React.useState<
		RequirementOfRequirementsField[]
	>([]);

	useEffect(() => {
		if (!data?.requirement?.id) {
			return;
		}
		if (!loadedReqs.find(req => req.id === data.requirement!.id)) {
			setLoadedReqs([...loadedReqs, data.requirement]);
		}
	}, [data, loadedReqs]);

	const loadedTooltipContent = React.useCallback(
		(renderItem: RequirementOfRequirementsField) => {
			return (
				<Stack>
					<Stack horizontal>
						<RequirementsFieldName>{t('Title')}:</RequirementsFieldName>
						<Link to={`/requirements/${renderItem.id}`} target={'_blank'}>
							<Text>
								{renderItem.name} <FontIcon iconName='OpenInNewWindow' />
							</Text>
						</Link>
					</Stack>
					<Stack horizontal>
						<RequirementsFieldName>{t('Status')}:</RequirementsFieldName>
						<Text>{getStatusTag(renderItem.status, theme, tEnum)}</Text>
					</Stack>
					<Stack horizontal>
						<RequirementsFieldName>{t('RequirementId')}:</RequirementsFieldName>
						<Text>{renderItem.requirementId || '-'}</Text>
					</Stack>
					<Stack horizontal>
						<RequirementsFieldName>{t('Definition')}:</RequirementsFieldName>
						<Text>
							<RichtextDisplay
								removeHtml
								value={renderItem.definition || '-'}
								limit={200}
							/>
						</Text>
					</Stack>
					<VexClustersField clusters={renderItem.vexClusters} />
				</Stack>
			);
		},
		[],
	);

	const renderRequirementWithTooltip = (
		requirement: RequirementOfRequirementsField,
	) => {
		const loadedReq = loadedReqs.find(req => req.id === requirement.id);
		const renderItem = loadedReq ?? requirement;
		return (
			<TooltipHost
				key={`${i}`}
				onTooltipToggle={(isTooltipVisible: boolean) => {
					if (
						isTooltipVisible &&
						!loadedReqs.find(req => req.id === requirement.id)
					) {
						getRequirementTooltipDetails({
							variables: {
								requirementId: requirement.id,
							},
						});
					}
				}}
				tooltipProps={{
					onRenderContent: () =>
						isLazy && !loadedReq ? (
							<LoadSpinner
								extraStyles={{root: {height: '60px', top: '30px'}}}
							/>
						) : (
							loadedTooltipContent(renderItem)
						),
				}}
				delay={TooltipDelay.zero}
				id={`reqToolTip-${renderItem.id}-${item.id}`}
				directionalHint={DirectionalHint.leftCenter}
			>
				{renderItem.name}
			</TooltipHost>
		);
	};
	return renderArrayField(renderRequirementWithTooltip)(item, i, col);
};

// eslint-disable-next-line complexity
export const getStatusTag = (
	status: any,
	theme: Theme,
	t: TFunction,
	reapprovingVKO?: string,
) => {
	let background;
	let color;

	switch (status) {
		case StatusEnums.Draft:
		case StatusEnums.Approval:
		case StatusEnums.New:
			background = theme.palette.neutralLight;
			color = theme.palette.neutralSecondary;
			break;
		case StatusEnums.Final:
		case StatusEnums.Finalized:
		case StatusEnums.Clearing:
			background = `${theme.palette.greenLight}33`;
			color = theme.palette.green;
			break;
		case StatusEnums.Interpretation:
			background = theme.palette.blueLight;
			color = theme.palette.blue;
			break;
		case StatusEnums.Discarded:
			background = `${theme.palette.red}33`;
			color = theme.palette.redDark;
			break;
		case StatusEnums.Examination:
			background = `${theme.palette.black}`;
			color = theme.palette.white;
			break;
		case StatusEnums.Consideration:
		case StatusEnums.InProgressExternal:
		case StatusEnums.Modified:
		case 'APPROVING_MODIFIED':
			background = `${theme.palette.yellow}33`;
			color = theme.palette.neutralSecondary;
			break;
		case StatusEnums.QualityControlInternal:
		case StatusEnums.QualityControlExternal:
			background = theme.palette.blue;
			color = theme.palette.white;
			break;
		case StatusEnums.InProgressInternal:
			background = theme.palette.tealLight;
			color = theme.palette.white;
			break;
		case StatusEnums.Archived:
			background = theme.palette.orangeLighter;
			color = theme.palette.white;
			break;
		case StatusEnums.ClearingWithReservations:
			background = theme.palette.yellow;
			color = theme.palette.neutralSecondary;
			break;
		case StatusEnums.Interim:
			background = `${theme.palette.greenLight}33`;
			color = theme.palette.green;
			break;
		case StatusEnums.InterimCompleted:
			background = `${theme.palette.greenLight}33`;
			color = theme.palette.green;
			break;
		default:
			break;
	}

	const className = mergeStyles({
		background,
		color,
		paddingLeft: 3,
		paddingRight: 3,
		borderRadius: 3,
	});

	const displayedStatus =
		status === StatusEnums.Modified && isApprovalModified(reapprovingVKO || '')
			? t('APPROVING_MODIFIED')
			: t(status);

	return <Text className={className}>{displayedStatus}</Text>;
};
