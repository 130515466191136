import React from 'react';
import {mergeStyles} from '@fluentui/react';
import {EntityListColumn as Column} from 'components';
import {
	renderParagraph,
	renderRequirements,
	renderArrayField,
	renderPhase,
	renderFaqs,
	renderLazyRequirements,
} from 'components/EntityList/ColumnRenderers';
import {KeywordFields} from 'components/Keywords/keywordItem.types';
import {getDateColumn} from 'helpers';
import {DateTimeFormat, formatDateTime} from 'i18n/localeDateFormat';
import i18next from 'i18next';
import {TFunction} from 'react-i18next';
import {Keyword, PhaseDate} from 'types';
import {ParagraphListKeywords} from './ParagraphListKeywords';
import {parseTooltipNewlines} from 'helpers/tooltips';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';

export const getCreateParagraphFieldWithDefaults = (filterable: boolean) => {
	return (fields: Column): Column => {
		return {
			isMultiline: true,
			isResizable: true,
			filterable,
			...fields,
		};
	};
};

interface ParagraphListItem {
	keywords: KeywordFields[];
}

export const getParagraphListBaseColumns = (
	t: TFunction,
	tt: TFunction,
	filterable = true,
	lazyLoadedTooltips = false,
	myKeywords?: Keyword[] | undefined,
	userContext?: {
		isVko: boolean;
		isVex: boolean;
		isReader: boolean;
		isBedarfskoordinator: boolean;
	},
): Column[] => {
	const {isVko, isVex, isReader, isBedarfskoordinator} = userContext
		? userContext
		: {
				isVex: undefined,
				isVko: undefined,
				isReader: undefined,
				isBedarfskoordinator: undefined,
		  };

	const paragraphClassName = mergeStyles({
		paddingTop: '0px !important',
		paddingBottom: '0px !important',
		height: '100% !important',
	});

	const hideSpecificColumns = (
		columns: Column[],
		hiddenColumnKeys: string[],
	): Column[] => {
		return columns.map(c => {
			if (hiddenColumnKeys.indexOf(c.key) !== -1) {
				return {
					...c,
					hidden: true,
				};
			}
			return c;
		});
	};

	const getExtraFieldsForColumns = (): Column[] => {
		const extraColumns = [
			{
				key: 'p-paragraph',
				name: t('Paragraph'),
				fieldName: 'name',
				minWidth: 400,
				className: paragraphClassName,
				filterable: false,
				onRender: (item: any) => renderParagraph(item, t),
			},
			{
				key: 'p-categories',
				name: t('Categories'),
				fieldName: 'categories',
				minWidth: 100,
				onRender: renderArrayField(),
			},
			{
				key: 'p-keywords',
				name: t('Keywords'),
				fieldName: 'keywords',
				minWidth: 100,
				onRender(item: ParagraphListItem): JSX.Element {
					return (
						<ParagraphListKeywords
							myKeywords={myKeywords}
							paragraphKeywords={item.keywords}
						/>
					);
				},
			},
			{
				key: 'p-requirements',
				name: t('Requirements'),
				fieldName: 'requirements',
				minWidth: 100,
				onRender:
					lazyLoadedTooltips === true
						? renderLazyRequirements()
						: renderRequirements(),
			},
			{
				key: 'p-tags',
				name: t('Tags'),
				fieldName: 'tags',
				minWidth: 150,
				onRender: renderArrayField(),
			},
			{
				key: 'p-vehicle-category',
				name: t('VehicleCategory'),
				fieldName: 'vehicleCategories',
				minWidth: 100,
				onRender: renderArrayField(),
			},
			{
				key: 'p-drive-variants',
				name: t('DriveVariants'),
				fieldName: 'driveVariants',
				minWidth: 100,
				onRender: renderArrayField(),
				tooltipHostProps: {
					content: parseTooltipNewlines(tt('driveVariants')),
				},
			},

			getDateColumn(
				'p-dateNewTypes',
				t('DateNewTypes'),
				'dateNewTypes',
				filterable,
				true,
				100,
			),
			getDateColumn(
				'p-dateNewRegistration',
				t('DateNewRegistration'),
				'dateNewRegistration',
				filterable,
				true,
				100,
			),
			getDateColumn(
				'p-comprehensive',
				t('Comprehensive'),
				'comprehensive',
				filterable,
				true,
				100,
			),
			{
				key: 'p-modelYear',
				name: t('ModelYear'),
				fieldName: 'modelYear',
				minWidth: 100,
			},
			{
				key: 'p-phaseIn',
				name: t('PhaseIn'),
				fieldName: 'phaseIn',
				minWidth: 200,
				getFilterKey: getPhaseFilterKey,
				getFilterLabel: getPhaseFilterLabel,
				onRender: renderPhase(),
			},
			{
				key: 'p-phaseOut',
				name: t('PhaseOut'),
				fieldName: 'phaseOut',
				minWidth: 200,
				getFilterKey: getPhaseFilterKey,
				getFilterLabel: getPhaseFilterLabel,
				onRender: renderPhase(),
			},
		];

		if (isVex === true) {
			return hideSpecificColumns(extraColumns, [
				'p-categories',
				'p-vehicle-category',
				'p-tags',
				'p-drive-variants',
				'p-dateNewTypes',
				'p-dateNewRegistration',
				'p-phaseIn',
				'p-phaseOut',
			]);
		}

		if (isVko === true) {
			return hideSpecificColumns(extraColumns, ['p-requirements', 'p-tags']);
		}

		if (isReader === true) {
			return hideSpecificColumns(extraColumns, [
				'p-categories',
				'p-vehicle-category',
				'p-tags',
				'p-drive-variants',
				'p-dateNewTypes',
				'p-dateNewRegistration',
				'p-phaseIn',
				'p-phaseOut',
			]);
		}

		if (isBedarfskoordinator === true) {
			const data = hideSpecificColumns(extraColumns, [
				'p-categories',
				'p-vehicle-category',
				'p-drive-variants',
				'p-dateNewTypes',
				'p-dateNewRegistration',
				'p-phaseIn',
				'p-phaseOut',
			]);
			return data;
		}

		return extraColumns;
	};

	const getColumns = (): Column[] => {
		const extraFields: Column[] =
			isDevEnvironment() || isTestEnvironment()
				? getExtraFieldsForColumns().concat({
						key: 'faq',
						name: 'FAQs',
						fieldName: 'faq',
						isMultiline: true,
						minWidth: 400,
						isResizable: true,
						filterable: true,
						onRender: renderFaqs(),
				  })
				: getExtraFieldsForColumns();
		return extraFields.map(getCreateParagraphFieldWithDefaults(filterable));
	};

	return getColumns();
};

export function getPhaseFilterKey(val: PhaseDate) {
	return `${val.date} ${val.status}`;
}

export function getPhaseFilterLabel(val: PhaseDate) {
	return `${formatDateTime(
		new Date(val.date),
		i18next,
		DateTimeFormat.DateShort,
	)} (${val.status})`;
}
