import {
	Breadcrumb,
	IBreadcrumbItem,
	Pivot,
	PivotItem,
	SelectionMode,
} from '@fluentui/react';
import {DetailsField, DetailsSection} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {RegulationsList} from 'features/Regulations/components';
import {ParagraphsList} from 'features/RegulatoryDocuments/components/DocumentDetails';
import {ParagraphsContextProvider} from 'features/RegulatoryDocuments/components/DocumentDetails/ParagraphsContext';
import _ from 'lodash';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {PermissionsForm} from './PermissionsForm';
import {QueryResult} from '@apollo/client';
import {CommonDataForDetailsPage as PageData} from './DetailsPageForEntityLikeAProject.types';
import {VehicleProject} from 'types';
import {AssignVehicleProjectRequirementsForm} from './AssignVehicleProjectRequirementsForm';

type DefaultArray = never[];
const emptyArray: DefaultArray = [];

export type RenderRequirementsList = () => JSX.Element;

export interface PropsForDetailsPageForEntityLikeAProject {
	breadcrumbs: IBreadcrumbItem[];
	isLoading: QueryResult['loading'];
	refetchPageData: QueryResult['refetch'];
	pageData: PageData | undefined | null;
	simulationName?: string | undefined;
	generation: VehicleProject['generation'] | null | undefined;
	isSimulationDetailsPage?: boolean;
	renderRequirementsList: RenderRequirementsList;
}

export const DetailsPageForEntityLikeAProject: React.FC<
	PropsForDetailsPageForEntityLikeAProject
> = ({
	breadcrumbs,
	isLoading,
	refetchPageData,
	pageData,
	simulationName,
	generation,
	isSimulationDetailsPage,
	renderRequirementsList,
}) => {
	const {t} = useTranslation('features/vehicleprojects');

	// Use the refetch function somewhere in your component
	const refreshData = async () => {
		await refetchPageData();
	};

	/**
	 * Note that the default value will only be returned if the value is undefined
	 * (not null).
	 */
	const getWithDefaultArray = useCallback(
		<Key extends keyof PageData>(
			key: Key,
		): Exclude<PageData[Key], undefined> | DefaultArray => {
			return _.get(pageData, key, emptyArray);
		},
		[pageData],
	);

	const initialFieldRows: DetailsField[][] = React.useMemo(() => {
		return [
			[
				{
					data: pageData?.markets,
					type: 'array',
					label: t('SalesMarkets'),
				},
				{
					data: pageData?.derivatives,
					type: 'stringarray',
					label: t('Derivative'),
				},
				{
					data: pageData?.earliestSOP,
					type: 'date',
					label: t('EarliestSOP'),
				},
				{
					data: pageData?.latestEOP,
					type: 'date',
					label: t('LatestEOP'),
				},
			],
		];
	}, [pageData, t]);

	const vehicleProjectIdWithDefault: VehicleProject['id'] =
		pageData?.vehicleProjectId ?? '';

	return (
		<LoadWrapper loading={isLoading}>
			<Breadcrumb items={breadcrumbs} />
			{isSimulationDetailsPage ? null : (
				<PermissionsForm
					refreshData={refreshData}
					userArray={getWithDefaultArray('userWPermissions')}
					loading={isLoading}
				/>
			)}
			<DetailsSection
				title={simulationName ?? generation ?? ''}
				initialFieldRows={initialFieldRows}
			/>
			<Pivot>
				<PivotItem headerText={t('AssignedRequirements')}>
					{renderRequirementsList()}
					{isSimulationDetailsPage ? null : (
						<AssignVehicleProjectRequirementsForm
							vehicleProjectId={vehicleProjectIdWithDefault}
							refreshData={refreshData}
						/>
					)}
				</PivotItem>
				<PivotItem headerText={t('AssignedRegulations')}>
					<RegulationsList
						regulations={getWithDefaultArray('regulations')}
						selectionMode={SelectionMode.none}
					/>
				</PivotItem>
				<PivotItem headerText={t('AssignedParagraphs')}>
					<ParagraphsContextProvider>
						<ParagraphsList
							paragraphs={getWithDefaultArray('paragraphs')}
							selectionMode={SelectionMode.none}
							fromVehicleProjects={true}
							includeRegulationAndVersionName={true}
						/>
					</ParagraphsContextProvider>
				</PivotItem>
			</Pivot>
		</LoadWrapper>
	);
};
