import {PanelType} from '@fluentui/react';
import {EntityListColumn, EntityPage} from 'components';
import {LoadWrapper} from 'components/LoadWrapper';
import {useGetConvolutesQuery} from 'features/AdminSection/hooks/convolutes.generated';
import {useGetGdprRuleQuery} from 'features/AdminSection/hooks/gdprRules.generated';
import {getTextColumn, mapToRef} from 'helpers';
import React from 'react';
import {Control} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ConvoluteType, VehicleProject, UserRole} from 'types';
import VehicleProjectFormElements from './components/VehicleProjectFormElements';
import {
	GetVehicleProjectOverviewPageDataDocument,
	useCreateVehicleProjectMutation,
	useDeleteVehicleProjectMutation,
	useUpdateVehicleProjectMutation,
	useGetVehicleProjectOverviewPageDataQuery,
} from './hooks/vehicleProjects.generated';
import {ApolloCache} from '@apollo/client';
import {VehicleProjectsTooltipTranslationProvider} from './components/VehicleProjectsTooltipTranslationProvider';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';

const VehicleProjectsPage: React.FC = () => {
	const {t} = useTranslation('features/vehicleprojects');

	const [createVehicleProjectMutation] = useCreateVehicleProjectMutation();
	const [updateVehicleProjectMutation] = useUpdateVehicleProjectMutation();
	const [deleteVehicleProjectMutation] = useDeleteVehicleProjectMutation();

	const {loading, data} = useGetVehicleProjectOverviewPageDataQuery();
	const groupedVehicleProjects = data?.vehicleProjectOverviewPageData ?? [];

	const sortedVehicleProjects = React.useMemo(() => {
		return [...groupedVehicleProjects].sort((a, b) =>
			a.key.localeCompare(b.key),
		);
	}, [groupedVehicleProjects]);

	const refetchQueries = [GetVehicleProjectOverviewPageDataDocument];

	const {data: convolutes} = useGetConvolutesQuery();
	const convolute = React.useMemo(
		() =>
			convolutes?.convolutes?.find(
				c => c.convoluteType === ConvoluteType.VehicleProject,
			),
		[convolutes],
	);

	const {data: gdprRule} = useGetGdprRuleQuery({
		variables: {
			id: convolute?.gdprRule?.id ?? '',
		},
	});

	const rule = React.useMemo(() => gdprRule?.gdprRule, [gdprRule]);

	type CacheKeys = string[];

	const getCacheKeysToEvict = (): CacheKeys => {
		return [
			'vehicleProjectsByModelSeriesGenerationActiveTypeMarket',
			'activeTypeMarketsForModelSeriesAndGeneration',
		];
	};

	type ApolloCacheWithTypes = ApolloCache<any>;

	const getCacheEvictorForKey = (cache: ApolloCacheWithTypes) => {
		return (key: CacheKeys[number]): void => {
			cache.evict({fieldName: key});
		};
	};

	const evictCacheForProject = (cache: ApolloCacheWithTypes): void => {
		const cacheKeys: CacheKeys = getCacheKeysToEvict();
		const evictCacheForKey = getCacheEvictorForKey(cache);
		cacheKeys.forEach(evictCacheForKey);
		cache.gc();
	};

	const createVehicleProject = React.useCallback(
		(vehicleProject: VehicleProject) => {
			vehicleProject.willBeAnonymized = rule?.anonymize ?? false;
			vehicleProject.deletionPeriod = rule?.deletionPeriod ?? 0;
			const input = mapToInput(vehicleProject);
			createVehicleProjectMutation({
				variables: {
					input,
				},
				refetchQueries,
				update: evictCacheForProject,
			});
		},
		[rule],
	);

	const updateVehicleProject = React.useCallback(
		(vehicleProject: VehicleProject) => {
			const input = mapToInput(vehicleProject);
			updateVehicleProjectMutation({
				variables: {
					input: {
						id: vehicleProject.id,
						...input,
					},
				},
				refetchQueries,
			});
		},
		[],
	);

	const deleteVehicleProject = React.useCallback((id: string) => {
		deleteVehicleProjectMutation({
			variables: {
				input: {id},
			},
			refetchQueries,
		});
	}, []);

	const columns: EntityListColumn[] = React.useMemo(
		() =>
			[
				getTextColumn(
					'generation',
					t('ModellSerieName'),
					'generation',
					false,
					true,
				),
				getTextColumn(
					'requirementsCount',
					t('RequirementsCount'),
					'requirementsCount',
					false,
					false,
				),
			] as EntityListColumn[],
		[t],
	);

	const formElements = (control: Control<VehicleProject>) => {
		return (
			<div>
				<VehicleProjectFormElements control={control} />
			</div>
		);
	};

	const navigate = useNavigate();
	const onViewVehicleProject = React.useCallback(
		(vp: VehicleProject) =>
			navigate(
				`/vehicleProjects/${encodeURIComponent(
					vp.modelSeries,
				)}/${encodeURIComponent(vp.generation)}`,
			),
		[navigate],
	);

	return (
		<>
			<HeaderForPagesWithEntityPage>
				<h3>{t('VehicleProjects')}</h3>
				<p style={{marginTop: 20}}>{t('VehicleProjectsAccessPermission')}</p>
			</HeaderForPagesWithEntityPage>
			<LoadWrapper loading={loading}>
				<VehicleProjectsTooltipTranslationProvider>
					<EntityPage
						items={sortedVehicleProjects as any}
						entityDisplayName={t('VehicleProjects')}
						createEntity={createVehicleProject}
						updateEntity={updateVehicleProject}
						deleteEntity={deleteVehicleProject}
						panelType={PanelType.largeFixed}
						additionalColumns={columns}
						renderAdditionalFormElements={control => formElements(control)}
						onViewItem={onViewVehicleProject}
						groupListByFieldName={'modelSeries'}
						withNameColumn={false}
						hideDelete={true}
						hideEdit={true}
						rolesCreate={[UserRole.SystemAdministrator]}
						shouldWrapListWithScrollablePane={false}
					/>
				</VehicleProjectsTooltipTranslationProvider>
			</LoadWrapper>
		</>
	);
};

export function mapToInput(vehicleProject: VehicleProject) {
	return {
		derivative: vehicleProject.derivative,
		generation: vehicleProject.generation,
		modelSeries: vehicleProject.modelSeries,
		modelYear: vehicleProject.modelYear
			? parseInt(vehicleProject.modelYear as any, 10)
			: undefined,
		euLegislation: vehicleProject.euLegislation,
		isElectro: vehicleProject.isElectro,
		eopVsi: vehicleProject.eopVsi,
		detVsi: vehicleProject.detVsi,
		p10Vsi: vehicleProject.p10Vsi,
		typStartVsi: vehicleProject.typStartVsi,
		sysgenVsi: vehicleProject.sysgenVsi,
		sopVsi: vehicleProject.sopVsi,
		sosVsi: vehicleProject.sosVsi,
		wvtaVsi: vehicleProject.wvtaVsi,
		posEtVsi: vehicleProject.posEtVsi,
		posVsi: vehicleProject.posVsi,
		activeTypeMarketRefs: mapToRef(vehicleProject?.activeTypeMarkets),
		engineVariantRefs: mapToRef(vehicleProject?.engineVariants),
		driveVariantRefs: mapToRef(vehicleProject?.driveVariants),
		bodyworkVariantRefs: mapToRef(vehicleProject?.bodyworkVariants),
		gearboxVariantRefs: mapToRef(vehicleProject?.gearboxVariants),
		marketRefs: mapToRef(vehicleProject?.markets),
		deletionPeriod: vehicleProject.deletionPeriod,
		willBeAnonymized: vehicleProject.willBeAnonymized,
	};
}

export default VehicleProjectsPage;
