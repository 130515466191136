import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuickSearchRegulationsQueryVariables = Types.Exact<{
  searchString: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type QuickSearchRegulationsQuery = { __typename?: 'Query', searchRegulations: { __typename?: 'FullTextRegulationResult', regulations: Array<{ __typename?: 'Regulation', id: string, regulationNumber: string, name: string }> } };

export type QuickSearchRequirementsQueryVariables = Types.Exact<{
  searchString: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type QuickSearchRequirementsQuery = { __typename?: 'Query', searchRequirements: { __typename?: 'FullTextRequirementResult', requirements: Array<{ __typename?: 'Requirement', id: string, name: string, requirementId?: string | null, definition?: string | null }> } };

export type QuickSearchPogisQueryVariables = Types.Exact<{
  searchString: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type QuickSearchPogisQuery = { __typename?: 'Query', searchPogisDocuments: { __typename?: 'FullTextPogisDocumentResult', pogisDocuments: Array<{ __typename?: 'PogisDocument', shortInfo: string }> } };

export type QuickSearchDocumentsQueryVariables = Types.Exact<{
  searchString: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type QuickSearchDocumentsQuery = { __typename?: 'Query', searchRegulatoryDocumentsAndParagraphs: { __typename?: 'FullTextDocumentResult', regulatoryDocuments: Array<{ __typename?: 'RegulatoryDocument', id: string, name: string, regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string, name: string } | null }>, paragraphs: Array<{ __typename?: 'RegulatoryDocumentParagraph', id: string, enumeration: string, elements: Array<{ __typename?: 'RegulatoryDocumentParagraphElement', type: Types.ParagraphElementType, text?: string | null }>, parent: { __typename?: 'RegulatoryDocument', id: string, name: string, regulation?: { __typename?: 'Regulation', id: string, regulationNumber: string, name: string } | null } }> } };


export const QuickSearchRegulationsDocument = gql`
    query QuickSearchRegulations($searchString: String!, $limit: Int) {
  searchRegulations(searchString: $searchString, limit: $limit) {
    regulations {
      id
      regulationNumber
      name
    }
  }
}
    `;

/**
 * __useQuickSearchRegulationsQuery__
 *
 * To run a query within a React component, call `useQuickSearchRegulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchRegulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchRegulationsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQuickSearchRegulationsQuery(baseOptions: Apollo.QueryHookOptions<QuickSearchRegulationsQuery, QuickSearchRegulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickSearchRegulationsQuery, QuickSearchRegulationsQueryVariables>(QuickSearchRegulationsDocument, options);
      }
export function useQuickSearchRegulationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchRegulationsQuery, QuickSearchRegulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickSearchRegulationsQuery, QuickSearchRegulationsQueryVariables>(QuickSearchRegulationsDocument, options);
        }
export type QuickSearchRegulationsQueryHookResult = ReturnType<typeof useQuickSearchRegulationsQuery>;
export type QuickSearchRegulationsLazyQueryHookResult = ReturnType<typeof useQuickSearchRegulationsLazyQuery>;
export type QuickSearchRegulationsQueryResult = Apollo.QueryResult<QuickSearchRegulationsQuery, QuickSearchRegulationsQueryVariables>;
export const QuickSearchRequirementsDocument = gql`
    query QuickSearchRequirements($searchString: String!, $limit: Int) {
  searchRequirements(searchString: $searchString, limit: $limit) {
    requirements {
      id
      name
      requirementId
      definition
    }
  }
}
    `;

/**
 * __useQuickSearchRequirementsQuery__
 *
 * To run a query within a React component, call `useQuickSearchRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchRequirementsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQuickSearchRequirementsQuery(baseOptions: Apollo.QueryHookOptions<QuickSearchRequirementsQuery, QuickSearchRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickSearchRequirementsQuery, QuickSearchRequirementsQueryVariables>(QuickSearchRequirementsDocument, options);
      }
export function useQuickSearchRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchRequirementsQuery, QuickSearchRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickSearchRequirementsQuery, QuickSearchRequirementsQueryVariables>(QuickSearchRequirementsDocument, options);
        }
export type QuickSearchRequirementsQueryHookResult = ReturnType<typeof useQuickSearchRequirementsQuery>;
export type QuickSearchRequirementsLazyQueryHookResult = ReturnType<typeof useQuickSearchRequirementsLazyQuery>;
export type QuickSearchRequirementsQueryResult = Apollo.QueryResult<QuickSearchRequirementsQuery, QuickSearchRequirementsQueryVariables>;
export const QuickSearchPogisDocument = gql`
    query QuickSearchPogis($searchString: String!, $limit: Int) {
  searchPogisDocuments(searchString: $searchString, limit: $limit) {
    pogisDocuments {
      shortInfo
    }
  }
}
    `;

/**
 * __useQuickSearchPogisQuery__
 *
 * To run a query within a React component, call `useQuickSearchPogisQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchPogisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchPogisQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQuickSearchPogisQuery(baseOptions: Apollo.QueryHookOptions<QuickSearchPogisQuery, QuickSearchPogisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickSearchPogisQuery, QuickSearchPogisQueryVariables>(QuickSearchPogisDocument, options);
      }
export function useQuickSearchPogisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchPogisQuery, QuickSearchPogisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickSearchPogisQuery, QuickSearchPogisQueryVariables>(QuickSearchPogisDocument, options);
        }
export type QuickSearchPogisQueryHookResult = ReturnType<typeof useQuickSearchPogisQuery>;
export type QuickSearchPogisLazyQueryHookResult = ReturnType<typeof useQuickSearchPogisLazyQuery>;
export type QuickSearchPogisQueryResult = Apollo.QueryResult<QuickSearchPogisQuery, QuickSearchPogisQueryVariables>;
export const QuickSearchDocumentsDocument = gql`
    query QuickSearchDocuments($searchString: String!, $limit: Int) {
  searchRegulatoryDocumentsAndParagraphs(
    searchString: $searchString
    limit: $limit
  ) {
    regulatoryDocuments {
      id
      name
      regulation {
        id
        regulationNumber
        name
      }
    }
    paragraphs {
      id
      enumeration
      elements {
        type
        text
      }
      parent {
        id
        regulation {
          id
          regulationNumber
          name
        }
        name
      }
    }
  }
}
    `;

/**
 * __useQuickSearchDocumentsQuery__
 *
 * To run a query within a React component, call `useQuickSearchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickSearchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickSearchDocumentsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQuickSearchDocumentsQuery(baseOptions: Apollo.QueryHookOptions<QuickSearchDocumentsQuery, QuickSearchDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickSearchDocumentsQuery, QuickSearchDocumentsQueryVariables>(QuickSearchDocumentsDocument, options);
      }
export function useQuickSearchDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickSearchDocumentsQuery, QuickSearchDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickSearchDocumentsQuery, QuickSearchDocumentsQueryVariables>(QuickSearchDocumentsDocument, options);
        }
export type QuickSearchDocumentsQueryHookResult = ReturnType<typeof useQuickSearchDocumentsQuery>;
export type QuickSearchDocumentsLazyQueryHookResult = ReturnType<typeof useQuickSearchDocumentsLazyQuery>;
export type QuickSearchDocumentsQueryResult = Apollo.QueryResult<QuickSearchDocumentsQuery, QuickSearchDocumentsQueryVariables>;