import React from 'react';
import {useTranslation} from 'react-i18next';
import {FullTextSearchRequirementsQuery} from '../hooks';
import {SearchResultList} from './SearchResultList';
import {RequirementsTooltipTranslationProvider} from 'features/Requirements/RequirementsTooltipTranslationProvider';
import {useRequirementColumns} from 'features/Requirements/hooks/useRequirementColumns';

export const RequirementResultsList: React.FC<{
	requirements: FullTextSearchRequirementsQuery['searchRequirements']['requirements'];
}> = ({requirements}) => {
	const {t} = useTranslation('features/requirements', {
		keyPrefix: 'RequirementsList',
	});

	const columns = useRequirementColumns();

	return (
		<RequirementsTooltipTranslationProvider>
			<SearchResultList
				items={requirements}
				columns={columns}
				getItemUrl={req => `/requirements/${req?.id}`}
			/>
		</RequirementsTooltipTranslationProvider>
	);
};
