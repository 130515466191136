import {
	Callout,
	Checkbox,
	CheckboxVisibility,
	CommandButton,
	ConstrainMode,
	ContextualMenuItemType,
	DefaultButton,
	DetailsList,
	IBasePicker,
	IBasePickerSuggestionsProps,
	ICheckboxStyles,
	IColumn,
	IconButton,
	IDetailsHeaderProps,
	IDetailsList,
	IDetailsListProps,
	IDetailsListStyles,
	IGroup,
	IIconProps,
	IPanelStyles,
	IRefObject,
	IRenderFunction,
	ISelection,
	IStackItemStyles,
	ITag,
	ITagPickerProps,
	MarqueeSelection,
	Panel,
	PanelType,
	PrimaryButton,
	SelectionMode,
	Separator,
	Spinner,
	SpinnerSize,
	Stack,
	TagPicker,
	TextField,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {getRendererForHeaderWithTooltips} from '../../features/localizedTooltips/componentsWithTooltips/HeaderWithTooltipsAndStickySupport';
import {ColumnWithTooltipOptions} from 'features/localizedTooltips';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useCommand} from 'hooks';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {FilterItem} from './InfinityListFilterItem';
import {forEach} from 'lodash';

export type InfinityListColumn = ColumnWithTooltipOptions & {
	sortable?: boolean;
	filterable?: boolean;
	hidden?: boolean;
	filterOnFilter?: boolean;
	fieldValuesUnique?: string[];
	filterType?: string;
};

export type InfinityListProps = Omit<IDetailsListProps, 'columns'> & {
	columns: InfinityListColumn[];
	tableId?: string;
	fixedHeight?: string | number;
	listId?: string;
	sticky?: boolean;
	groupByFieldName?: string;
	stillLoading: boolean;
	initalFilters: InfinityListFilter[];
	firstRender: () => void;
	loadItemsNext: (currentId: string) => void;
	applyFilter: (filters: InfinityListFilter[]) => void;
};

export type InfinityListFilter = {
	columnKey: string;
	selection: string;
};

export interface InfinityListURLParams {
	[key: string]: any;
}

export const InfinityList: React.FC<InfinityListProps> = props => {
	const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> =
		getRendererForHeaderWithTooltips(props.sticky);

	const [isOpen, {setTrue: openPanel, setFalse: dismissPanel}] =
		useBoolean(false);

	const [allFilters, setAllFilters] = useState<InfinityListFilter[]>(
		props.initalFilters,
	);

	// Initial load of items
	useEffect(() => {
		props.firstRender();
	}, []);

	useCommand(
		{
			key: props.listId || 'filter',
			priority: 1,
			iconProps: {
				iconName: 'Filter',
			},
			farCommand: true,
			onClick: openPanel,
			ariaLabel: 'Filter',
			title: 'Filter',
		},
		[props.columns],
	);

	useCommand(
		{
			key: 'clearFilter',
			farCommand: true,
			hidden: allFilters.length === 0,
			iconProps: {
				iconName: 'ClearFilter',
			},
			onClick() {
				navigate({
					pathname: '',
				});
				setAllFilters([]);
				props.applyFilter([]);
			},
		},
		[allFilters],
	);

	const filterContent = React.useMemo(
		() =>
			props.columns.map((col, i) => {
				const {filterable, fieldName, filterType, fieldValuesUnique} = col;
				if (!filterable || filterType !== 'Array') {
					return <div key={i}></div>;
				}
				return (
					<div key={i}>
						{i !== 0 && <Separator />}
						<FilterItem
							col={col}
							allFilters={allFilters}
							setAllFilters={setAllFilters}
						/>
					</div>
				);
			}),
		[allFilters, props.columns],
	);

	const navigate = useNavigate();

	const onRenderFooterContent = React.useCallback(
		() => (
			<Stack horizontal tokens={{childrenGap: 10}}>
				<PrimaryButton
					text={'Anwenden'}
					onClick={() => {
						props.applyFilter(allFilters);
						dismissPanel();
					}}
				/>
				<DefaultButton
					text={'Filter zurücksetzen'}
					onClick={() => {
						setAllFilters([]);
						props.applyFilter([]);
					}}
				/>
			</Stack>
		),
		[allFilters, props.applyFilter],
	);

	return (
		<>
			<Callout
				role='alertdialog'
				target={{left: window.innerWidth - 20, top: window.innerHeight / 4}} // Place it at the right side
				hidden={props.stillLoading}
				isBeakVisible={false}
			>
				<div style={{padding: 20}}>
					{<Spinner size={SpinnerSize.medium} />}
					<p>Die Daten werden geladen!</p>
				</div>
			</Callout>
			<DetailsList
				{...props}
				checkboxVisibility={
					props.checkboxVisibility ??
					props.selectionMode === SelectionMode.multiple
						? CheckboxVisibility.always
						: CheckboxVisibility.onHover
				}
				styles={
					props.fixedHeight
						? getFixedHeightListStyles(props.fixedHeight)
						: props.styles
				}
				items={props.items}
				columns={props.columns}
				onRenderDetailsHeader={onRenderDetailsHeader}
				selectionPreservedOnEmptyClick={true}
				constrainMode={props.constrainMode || ConstrainMode.unconstrained}
				onRowDidMount={item => {
					props.loadItemsNext(item.id);
				}}
			/>
			<Panel
				type={PanelType.medium}
				isLightDismiss
				isOpen={isOpen}
				closeButtonAriaLabel='Close'
				onDismiss={dismissPanel}
				onRenderFooterContent={onRenderFooterContent}
				isFooterAtBottom={true}
				headerText='Filter'
			>
				{filterContent}
			</Panel>
		</>
	);
};

const getFixedHeightListStyles: (
	height: string | number,
) => Partial<IDetailsListStyles> = height => ({
	root: {
		overflowX: 'scroll',
		selectors: {
			'& [role=grid]': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'start',
				height,
			},
		},
	},
	headerWrapper: {
		flex: '0 0 auto',
	},
	contentWrapper: {
		flex: '1 1 auto',
		overflowY: 'auto',
		overflowX: 'hidden',
	},
});
