import {
	Label,
	Link,
	mergeStyleSets,
	SearchBox,
	Separator,
	Spinner,
	SpinnerSize,
	Theme,
	useTheme,
} from '@fluentui/react';
import {useOutsideClick} from 'hooks';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {RegulatoryDocumentParagraph} from 'types';
import {getParagraphLabel} from 'features/RegulatoryDocuments/utils';
import {
	useQuickSearchDocumentsLazyQuery,
	useQuickSearchPogisLazyQuery,
	useQuickSearchRegulationsLazyQuery,
	useQuickSearchRequirementsLazyQuery,
} from './hooks/useQuickSearch.generated';

type SearchSuggestions = {
	requirements: Array<any>;
	regulations: Array<any>;
	regulatoryDocuments: Array<any>;
	paragraphs: Array<any>;
	pogisDocuments: Array<any>;
};

export const TextSearchBar: React.FC = () => {
	const {t} = useTranslation('components/textsearchbar');
	const theme = useTheme();
	const navigate = useNavigate();
	const classNames = getClassNames(theme);

	const [runRequirementSearch, {loading: requirementsLoading}] =
		useQuickSearchRequirementsLazyQuery();
	const [runRegulationSearch, {loading: regulationsLoading}] =
		useQuickSearchRegulationsLazyQuery();
	const [runDocumentsSearch, {loading: documentsLoading}] =
		useQuickSearchDocumentsLazyQuery();
	const [runPogisSearch, {loading: pogisLoading}] =
		useQuickSearchPogisLazyQuery();

	const [searchText, setSearchText] = React.useState('');
	const [hideSuggestions, setHideSuggestions] = React.useState(false);
	// State to hold search suggestions
	const [suggestions, setSuggestions] = React.useState<SearchSuggestions>({
		requirements: [],
		regulations: [],
		regulatoryDocuments: [],
		paragraphs: [],
		pogisDocuments: [],
	});

	const onOutsideClick = React.useCallback(() => {
		setHideSuggestions(true);
	}, []);
	const ref = useOutsideClick(onOutsideClick);

	const onNavigate = React.useCallback(() => setHideSuggestions(true), []);

	React.useEffect(() => {
		if (searchText.length <= 2) {
			// Hide suggestions and do not run search on page load or if search text is too short
			setHideSuggestions(true);
			setSuggestions({
				requirements: [],
				regulations: [],
				regulatoryDocuments: [],
				paragraphs: [],
				pogisDocuments: [],
			});
			return;
		}

		const typingTimerId = setTimeout(() => {
			// Run all three search queries concurrently if the search text is valid
			Promise.all([
				runRequirementSearch({variables: {searchString: searchText, limit: 6}}),
				runRegulationSearch({variables: {searchString: searchText, limit: 6}}),
				runDocumentsSearch({variables: {searchString: searchText, limit: 6}}),
				runPogisSearch({variables: {searchString: searchText, limit: 6}}),
			]).then(([requirementsRes, regulationsRes, documentsRes, pogisRes]) => {
				setSuggestions({
					requirements:
						requirementsRes.data?.searchRequirements?.requirements || [],
					regulations:
						regulationsRes.data?.searchRegulations?.regulations || [],
					regulatoryDocuments:
						documentsRes.data?.searchRegulatoryDocumentsAndParagraphs
							?.regulatoryDocuments || [],
					paragraphs:
						documentsRes.data?.searchRegulatoryDocumentsAndParagraphs
							?.paragraphs || [],
					pogisDocuments:
						pogisRes.data?.searchPogisDocuments?.pogisDocuments || [],
				});
				setHideSuggestions(false);
			});
		}, 1000);

		return () => clearTimeout(typingTimerId);
	}, [searchText, runRegulationSearch, runDocumentsSearch, runPogisSearch]);

	const onSearch = React.useCallback(
		(text: string) => {
			if (text.length > 2) {
				setHideSuggestions(true);
				navigate(`/search/?text=${text}`);
			}
		},
		[navigate],
	);

	const loading =
		requirementsLoading ||
		regulationsLoading ||
		documentsLoading ||
		pogisLoading;

	return (
		<div className={classNames.componentWrapper} ref={ref}>
			<SearchBox
				placeholder={t('SearchPlaceholder')}
				onChange={(_, val) => val && setSearchText(val)}
				onClear={() => {
					setSearchText(''); // Clear search text state
					setSuggestions({
						requirements: [],
						regulations: [],
						regulatoryDocuments: [],
						paragraphs: [],
						pogisDocuments: [],
					});
					setHideSuggestions(true);
				}}
				onFocus={() => {
					if (
						searchText.length >= 3 &&
						(suggestions.requirements.length > 0 ||
							suggestions.regulations.length > 0 ||
							suggestions.regulatoryDocuments.length > 0 ||
							suggestions.paragraphs.length > 0 ||
							suggestions.pogisDocuments.length > 0)
					) {
						setHideSuggestions(false); // Only show suggestions if there are results
					} else {
						setHideSuggestions(true); // Hide suggestions if no valid input or results
					}
				}}
				onSearch={onSearch}
				styles={{
					root: {
						position: 'relative',
					},
				}}
			/>
			{loading && (
				<div className={classNames.suggestions}>
					<Spinner size={SpinnerSize.large} />
				</div>
			)}
			{suggestions && !hideSuggestions && !loading && (
				<div className={classNames.suggestions}>
					<ResultItem
						label='Requirements'
						items={suggestions.requirements}
						getUrl={req => `/requirements/${req?.id}`}
						renderItem={req => <>{`${req.name}`}</>}
						separator
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=requirements`}
					/>
					<ResultItem
						label='Regulations'
						items={suggestions.regulations}
						getUrl={reg => `/regulations/${reg?.id}`}
						renderItem={reg => <>{`${reg.regulationNumber} ${reg.name}`}</>}
						separator
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=regulations`}
					/>
					<ResultItem
						label='Regulatory Documents'
						items={suggestions.regulatoryDocuments}
						getUrl={regDoc =>
							regDoc?.regulation
								? `/regulations/${regDoc.regulation.id}/${regDoc.id}/paragraphs`
								: `/regulatoryDocuments/${regDoc?.id}`
						}
						renderItem={regDoc => (
							<>
								{regDoc.regulation
									? `${regDoc.regulation.regulationNumber} ${regDoc.regulation.name} > ${regDoc.name}`
									: regDoc.name}
							</>
						)}
						separator
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=regulatoryDocuments`}
					/>
					<ResultItem
						label='Paragraphs'
						items={suggestions.paragraphs}
						getUrl={par =>
							`/regulations/${par?.parent.regulation?.id}/${par?.parent.id}/paragraphs/${par?.id}`
						}
						renderItem={ParagraphItem}
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=paragraphs`}
					/>
					<ResultItem
						label={t('Pogis')}
						items={suggestions.pogisDocuments}
						renderItem={doc => <>{`${doc.shortInfo}`}</>}
						onNavigate={onNavigate}
						viewResultsUrl={`search?text=${searchText}&entity=pogisDocuments`}
					/>
				</div>
			)}
		</div>
	);
};

const ParagraphItem = (par: RegulatoryDocumentParagraph) => {
	const paragraphLabel = getParagraphLabel(par);

	return (
		<React.Fragment>
			{par.parent.regulation
				? `${par.parent.regulation.regulationNumber} ${par.parent.regulation.name} > ${par.parent.name} > ${paragraphLabel}`
				: `${par.parent.name} > ${paragraphLabel}`}
		</React.Fragment>
	);
};

type ResultItemProps<T> = {
	label: string;
	items: T[];
	renderItem: (item: T) => JSX.Element;
	getUrl?: (item?: T) => string;
	viewResultsUrl: string;
	separator?: boolean;
	onNavigate?: () => void;
};

function ResultItem<T>(props: ResultItemProps<T>) {
	const {
		label,
		items,
		renderItem,
		getUrl,
		viewResultsUrl,
		separator = false,
		onNavigate,
	} = props;

	const {t} = useTranslation('components/textsearchbar');
	const theme = useTheme();
	const {resultItem, moreButtonWrapper} = getClassNames(theme);

	const navigate = useNavigate();
	const getOnResultClick = React.useCallback(
		(url: string) => () => {
			onNavigate?.();
			navigate(url);
		},
		[navigate],
	);
	return (
		<>
			<Label>{label}</Label>
			{items.map((item, i) => (
				<div
					key={i}
					onClick={getUrl && getOnResultClick(getUrl(item))}
					className={resultItem}
				>
					{renderItem(item)}
				</div>
			))}
			<div className={moreButtonWrapper}>
				{items.length > 0 && (
					<Link
						onClick={() => {
							onNavigate?.();
							navigate(viewResultsUrl);
						}}
					>
						{t('MoreResults')}
					</Link>
				)}
			</div>
			{separator && <Separator />}
		</>
	);
}

const getClassNames = (theme: Theme) =>
	mergeStyleSets({
		componentWrapper: {
			width: 600,
			zIndex: 10,
			paddingRight: '50px',
			position: 'relative',
			top: '50%',
			'-webkit-transform': 'translateY(-50%)',
			'-ms-transform': 'translateY(-50%)',
			transform: 'translateY(-50%)',
		},
		suggestions: {
			background: theme.palette.white,
			position: 'absolute',
			zIndex: 10,
			width: 'calc(600px - 32px)',
			top: '35px',
			padding: '16px',
			boxShadow: `0px 3px 10px ${theme.palette.neutralPrimary}`,
			maxHeight: 'calc(100vh - 100px)',
			overflowY: 'scroll',
		},
		resultItem: {
			padding: '4px 0',
			cursor: 'pointer',
			':hover': {
				background: `${theme.palette.neutralLight}cc`,
			},
		},
		moreButtonWrapper: {
			textAlign: 'right',
		},
	});
