import {
	Persona,
	PersonaSize,
	Stack,
	useTheme,
	ITextStyles,
} from '@fluentui/react';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {getLocaleForDateFns} from 'i18n/localeDateFormat';

import {format} from 'date-fns';
import _ from 'lodash';
import {FaqReplies} from './FaqReplies';
import {
	ArrayValues,
	AttachmentFieldComponent,
} from 'components/EntityList/ColumnRenderers';

import {useQuestionsPropertiesData} from './hooks/useQuestionsPropertiesData';

export const FaqQuestions: any = ({
	questions,

	selectedQuestion,
	setSelectedQuestion,
	initQuestion,
}: any) => {
	const {t, i18n} = useTranslation('common/faq');
	const {questionCategories} = useQuestionsPropertiesData({t});

	const theme = useTheme();

	const locale: Locale = getLocaleForDateFns(i18n.language);
	const getTextStyles = (arrayItem: string): ITextStyles => ({
		root: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	});
	return (
		<div style={{width: '50%', overflowY: 'auto'}}>
			{questions?.map((elem: any) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
						marginBottom: '10px',
						cursor: 'pointer',

						border:
							selectedQuestion?.id === elem.id && !initQuestion
								? `${theme.palette.neutralDark} solid 3px`
								: '',
					}}
					key={elem.id}
					onClick={() => {
						setSelectedQuestion(elem);
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							padding: '10px',
						}}
					>
						<strong>
							<Persona
								aria-label='persona'
								size={PersonaSize.size24}
								text={elem.createdBy.name}
							/>
						</strong>
						<div
							style={{
								color: theme.palette.neutralDark,
								opacity: '0.7',
								marginRight: '8px',
							}}
						>
							|
						</div>
						<div style={{color: theme.palette.neutralDark, opacity: '0.7'}}>
							{format(new Date(elem.createdAt), 'dd LLLL yyyy HH:mm', {
								locale,
							})}
						</div>
						<AttachmentFieldComponent
							fieldValue={elem.attachments}
							i={undefined}
							t={t}
							theme={theme}
						/>
					</div>
					<Stack.Item style={{paddingLeft: '10px'}}>
						{t('QuestionType')}:{' '}
						<ArrayValues
							getTextStyles={getTextStyles}
							array={[
								t(
									questionCategories?.find(
										(e: any) => e?.key === elem?.category?.id,
									)?.text || '',
								),
							]}
						/>
					</Stack.Item>
					{elem?.vehicleProjects?.length > 0 && (
						<Stack.Item style={{paddingLeft: '10px', overflowX: 'hidden'}}>
							{t('VehicleProject')}:
							<ArrayValues
								array={elem?.vehicleProjects?.map(
									(e: any) => `${e.modelSeries}: ${e.generation} ( ${e.name} )`,
								)}
								getTextStyles={getTextStyles}
							/>
						</Stack.Item>
					)}

					{elem?.keywords?.length > 0 && (
						<Stack.Item style={{paddingLeft: '10px', overflowX: 'hidden'}}>
							{t('SelectKeywords')}:
							<ArrayValues
								array={elem?.keywords?.map((e: any) => e.name)}
								getTextStyles={getTextStyles}
							/>
						</Stack.Item>
					)}

					{elem?.roles?.length > 0 && (
						<Stack.Item style={{paddingLeft: '10px'}}>
							{t('SelectRoles')}: {elem?.roles?.map((e: any) => e)}
						</Stack.Item>
					)}

					<Stack.Item
						style={{
							margin: '10px',
							wordBreak: 'break-all',
							whiteSpace: 'break-spaces',
							display: '-webkit-box',
							WebkitLineClamp: selectedQuestion?.id === elem.id ? 'unset' : 3,
							WebkitBoxOrient: 'vertical',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxHeight: selectedQuestion?.id === elem.id ? 'none' : '4.5em',
						}}
					>
						{elem.name}
					</Stack.Item>
					<Stack.Item>
						<div
							style={{
								backgroundColor: theme.palette.neutralLight,

								padding: '10px',
								cursor: 'pointer',
								color: theme.palette.red,
							}}
						>
							{elem.answers.length ? (
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<FaqReplies answers={elem.answers} />
								</div>
							) : (
								t('NoReplies')
							)}
						</div>
					</Stack.Item>
				</div>
			))}
		</div>
	);
};
