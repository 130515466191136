import {
	DetailsRow,
	IDetailsRowProps,
	IDetailsRowStyles,
	IRenderFunction,
	SelectionMode,
	findScrollableParent,
	useTheme,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import {
	EntityList,
	EntityListColumn,
	EntityListProps,
	ROW_SELECTOR,
} from 'components';
import {
	ElementFieldsForParagraphColumn,
	FieldsForParagraphColumn,
	RequirementWithoutDetails,
	ValueForAttachmentColumn,
	renderAttachments,
	renderRichtext,
} from 'components/EntityList/ColumnRenderers';
import {
	useEditorSelectionContext,
	useRegulatoryDocumentsContext,
} from 'features/RegulatoryDocuments/context';
import {useSelection} from 'hooks';
import React, {useCallback, useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {
	Maybe,
	Regulation,
	RegulatoryDocumentParagraph,
	RegulatoryDocumentParagraphElement,
	WorkflowStatus,
} from 'types';
import {
	getCreateParagraphFieldWithDefaults,
	getParagraphListBaseColumns,
} from './ParagraphListBaseColumns/ParagraphListBaseColumns';
import {useParagraphsContext} from './ParagraphsContext';
import {RegDocParagraphTooltipTranslationProvider} from 'features/localizedTooltips';
import {useRegDocsTooltipText} from '../RegDocTooltipTranslationProvider';
import {SidebarInfoContextValue} from '../../../../context/SidebarInfoProvider';
import {KeywordFields} from 'components/Keywords/keywordItem.types';
import {RegDocSidebarInfoContext} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocSidebarInfoProvider';
import {ParagraphsListContextProvider} from './ParagraphsList.context';
import {ViewPDFDialog} from './FictiousSegmentDetails/ViewPDFDialog';
import {
	EditParagraphsFormSubmissionStatusContext,
	EditParagraphsFormSubmissionStatusInfo,
} from '../../RegDocDetailsPage/EditParagraphsFormSubmissionStatusProvider';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {ParagraphDocumentsCollectionName} from 'components/Faq/EntityTypeMapper';
import {useQuestionsData} from 'components/Faq/hooks/useQuestionsData';
import {FaqCommand} from 'components/Faq/FaqCommand';
import {
	ParagraphsListKeywordAssignment,
	ParagraphsListKeywordAssignments,
} from './ParagraphsListKeywordAssignments';

type OptionalFieldsOfRegulationOfParent = Partial<
	Pick<Regulation, 'regulationNumber'>
>;

type RegulationOfParent = Pick<Regulation, 'id'> &
	OptionalFieldsOfRegulationOfParent;

type BaseParent = RegulatoryDocumentParagraph['parent'];

interface Parent extends Pick<BaseParent, 'name' | 'id'> {
	regulation?: Maybe<RegulationOfParent>;
}

type Element = ElementFieldsForParagraphColumn &
	Pick<RegulatoryDocumentParagraphElement, 'bounds'>;

interface ObjectWithName {
	name: string;
}

/**
 * ! Important
 *
 * - Make sure to query the phase fields' date and status if you want them to show
 * correctly. (I didn't require those fields in the phase fields' types here
 * because it would cause the types of queries' data to be incompatible with
 * this Paragraphs List. We can fix that in the future.)
 * - Also query elements[number].asset
 *
 * The following fields are required if you want to track which lines on the
 * paragraph are selected:
 * - page
 * - elements[number].bounds
 *
 * * Other notes
 *
 * Note that the array fields' types might be requiring more fields than
 * necessary. However, this only applies to the array fields that we are picking
 * directly.
 */
interface ParagraphOfParagraphsList
	extends Pick<
			RegulatoryDocumentParagraph,
			| 'id'
			| 'dateNewTypes'
			| 'dateNewRegistration'
			| 'comprehensive'
			| 'modelYear'
			| 'phaseIn'
			| 'phaseOut'
			| 'summary'
		>,
		FieldsForParagraphColumn {
	parent?: Parent;
	keywords: KeywordFields[];
	elements: Element[];
	page?: number | null;
	keywordAssignments?: ParagraphsListKeywordAssignment[] | null;
	attachments?: ValueForAttachmentColumn;
	driveVariants: ObjectWithName[];
	categories: ObjectWithName[];
	vehicleCategories: ObjectWithName[];
	requirements: RequirementWithoutDetails[];
	tags: ObjectWithName[];
}

export type ParagraphsListProps = Omit<
	EntityListProps,
	'items' | 'columns' | 'onRenderRow' | 'selection' | 'initialFocusedIndex'
> & {
	paragraphs: ParagraphOfParagraphsList[];
	isSecondaryList?: boolean;
	listId?: string;
	regulationId?: string;
	regulatoryDocumentId?: string;
	includeRegulationAndVersionName?: boolean;
	filterable?: boolean;
	sticky?: boolean;
	fromRequirements?: boolean;
	fromVehicleProjects?: boolean;
	clearingAudits?: any;
	workflowStatus?: any;
	/**
	 * We usually do this to compensate for sticky headers covering part of the
	 * paragraphs
	 */
	amountToScrollBackByAfterScrollingToParagraph?: number;
};

export const ParagraphsList: React.FC<ParagraphsListProps> = ({
	paragraphs,
	isSecondaryList = false,
	listId = 'ParagraphsList',
	includeRegulationAndVersionName = false,
	regulationId,
	regulatoryDocumentId,
	filterable = true,
	sticky = true,
	fromRequirements = false,
	fromVehicleProjects = false,
	clearingAudits,
	workflowStatus,
	amountToScrollBackByAfterScrollingToParagraph: scrollOffset,

	...props
}) => {
	const paragraphsData = useQuestionsData({
		data: paragraphs,
		entityType: ParagraphDocumentsCollectionName,
		parentId: regulatoryDocumentId,
	});
	const theme = useTheme();
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphsList',
	});
	const {t: tt} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'TooltipsText',
	});

	const {
		setSelectedParagraphs,
		selectedParagraphs,
		setSecondSelectedParagraphs,
		scrollToParagraphId,
		indexToScrollTo,
		setIndexToScrollTo,
	} = useParagraphsContext<ParagraphOfParagraphsList>();

	const {
		isRegulationReader,
		isVex,
		isVko,
		isReader,
		isBedarfskoordinator,
		myKeywords,
	} = useUserContext();

	const {setSelectedTextLine} = useEditorSelectionContext();
	const {selectionMode} = useRegulatoryDocumentsContext();

	const getKey = (
		item: ParagraphOfParagraphsList,
	): ParagraphOfParagraphsList['id'] => {
		return item.id;
	};

	const [selection] = useSelection<ParagraphOfParagraphsList>({
		onSelectionChanged(selectedItems) {
			if (isSecondaryList) {
				setSecondSelectedParagraphs(selectedItems);
			} else {
				setSelectedParagraphs(selectedItems);
			}
		},
		getKey,
	});

	const [initialFocusedIndex, setInitialFocusedIndex] = React.useState(
		paragraphsData?.findIndex(
			(p: any) => p.id === localStorage?.getItem('paragraphId'),
		),
	);

	if (localStorage?.getItem('paragraphId')) {
		setInitialFocusedIndex(
			paragraphsData?.findIndex(
				(p: any) => p.id === localStorage?.getItem('paragraphId'),
			),
		);
		localStorage?.removeItem('paragraphId');
	}

	const onRenderRow: IRenderFunction<IDetailsRowProps> = (
		props: IDetailsRowProps | undefined,
	) => {
		const customStyles: Partial<IDetailsRowStyles> = {};

		if (props) {
			const uniqueIds1 = [
				...new Set(props.item?.auditLog?.map((a: any) => a.id)),
			];
			const uniqueIds2 = [...new Set(clearingAudits?.map((a: any) => a.id))];
			if (
				uniqueIds1?.some(id => uniqueIds2?.includes(id)) &&
				workflowStatus === WorkflowStatus.ClearingWithReservations &&
				isVko
			) {
				customStyles.fields = {
					display: 'flex',
					alignItems: 'start',
					background: `${theme.palette.yellow}`,
				};
			} else {
				customStyles.fields = {
					display: 'flex',
					alignItems: 'start',
					background: props.item.isFootnote
						? theme.palette.neutralLight
						: undefined,
				};
			}

			return <DetailsRow {...props} styles={customStyles} />;
		}

		return null;
	};

	// Status column
	const possibleKeywordAssignmentColumns: EntityListColumn[] = React.useMemo(
		() =>
			!isVko &&
			!isReader &&
			listId === 'ParagraphsList' &&
			(workflowStatus === WorkflowStatus.Finalized ||
				workflowStatus === WorkflowStatus.Modified ||
				workflowStatus === WorkflowStatus.Archived)
				? [
						{
							name: t('KeywordAssignments'),
							key: 'keywordAssignments',
							fieldName: 'keywordAssignments',
							minWidth: 100,
							isResizable: true,
							onRender: renderKeywordAssignments,
						},
				  ]
				: [],
		[t, listId, workflowStatus],
	);

	const getSummaryExtraColumnFields = useCallback((): EntityListColumn => {
		return {
			key: 'p-summary',
			name: t('Summary'),
			fieldName: 'summary',
			minWidth: 150,
			filterable: false,
			onRender: renderRichtext(),
		};
	}, [t]);

	const getSummaryField = useCallback((): EntityListColumn => {
		const createField = getCreateParagraphFieldWithDefaults(filterable);
		const extraColumnFields: EntityListColumn = getSummaryExtraColumnFields();
		return createField(extraColumnFields);
	}, [filterable, getSummaryExtraColumnFields]);

	const {t: getRegDocTranslation} = useRegDocsTooltipText();

	const columns: EntityListColumn[] = React.useMemo(() => {
		const cols = [
			...(includeRegulationAndVersionName
				? [
						{
							key: 'regulation',
							name: t('Regulation'),
							minWidth: 100,
							getFieldValue: (item: ParagraphOfParagraphsList) =>
								item.parent?.regulation?.regulationNumber,
							filterable: true,
							isResizable: true,
							onRender: (item: ParagraphOfParagraphsList) =>
								item.parent?.regulation?.regulationNumber,
							tooltipHostProps: {content: getRegDocTranslation('regulation')},
						},
						{
							key: 'parent',
							name: t('Version'),
							fieldName: 'parent',
							minWidth: 100,
							filterable: true,
							isResizable: true,
							onRender: (item: ParagraphOfParagraphsList) => item.parent?.name,
						},
				  ]
				: []),
			...possibleKeywordAssignmentColumns,
			...getParagraphListBaseColumns(t, tt, true, filterable, myKeywords, {
				isVko,
				isVex,
				isReader,
				isBedarfskoordinator,
			}),
			{
				name: t('Attachments'),
				key: 'attachments',
				isIconOnly: true,
				iconName: 'Attach',
				fieldName: 'attachments',
				minWidth: 16,
				maxWidth: 16,
				onRender: renderAttachments(),
			},
			getSummaryField(),
		];

		return isRegulationReader
			? cols.filter(
					col =>
						[
							'keywordAssignments',
							'p-tags',
							'p-requirements',
							'attachments',
							'p-summary',
						].indexOf(col.key) === -1,
			  )
			: cols;
	}, [
		t,
		filterable,
		myKeywords,
		includeRegulationAndVersionName,
		getRegDocTranslation,
		getSummaryField,
		possibleKeywordAssignmentColumns,
		isRegulationReader,
	]);

	const navigate = useNavigate();

	const sidebarInfo: SidebarInfoContextValue = useContext(
		RegDocSidebarInfoContext,
	);

	const navigateToDetailsPage = useCallback(
		(item: ParagraphOfParagraphsList): void => {
			if (fromRequirements) {
				navigate(
					`/regulations/${item?.parent?.regulation?.id}/${item?.parent?.id}/paragraphs/${item.id}`,
				);
			} else if (fromVehicleProjects) {
				/**
				 * Note: This might not work because the Vehicle Project paragraph's ID
				 * is different from the Regulatory Document Paragraph's ID. So, the
				 * Paragraphs List won't be able to find the paragraph to scroll to it.
				 */
				localStorage.setItem('paragraphId', item.id);
				navigate(
					`/regulations/${item?.parent?.regulation?.id}/${item?.parent?.id}/paragraphs`,
				);
			} else {
				navigate(
					`/regulations/${regulationId}/${regulatoryDocumentId}/paragraphs/${item.id}`,
				);
			}
		},
		[
			navigate,
			regulationId,
			regulatoryDocumentId,
			fromRequirements,
			fromVehicleProjects,
		],
	);

	const onItemInvoked = React.useCallback(
		(item: ParagraphOfParagraphsList) => {
			/**
			 * This means the user is on the reg doc details page.
			 */
			if (sidebarInfo) return sidebarInfo.setIsOpen(true);
			return navigateToDetailsPage(item);
		},
		[sidebarInfo, navigateToDetailsPage],
	);

	const onActiveItemChanged = React.useCallback(
		(item: ParagraphOfParagraphsList) => {
			const handleMissingProps = (): void => {
				return console.error(`A paragraph's "page" or "elements" are missing.`);
			};

			const setSelectedLineUsingPage = (page: number): void => {
				setSelectedTextLine({
					page,
					bounds: item.elements[0]?.bounds ?? [0, 0, 0, 0],
				});
			};

			/**
			 * Despite its type, this function doesn't always exist.
			 *
			 * If the function is truthy, then it means the developer wants to use it.
			 * So, we validate the page and elements below.
			 */
			if (!setSelectedTextLine) return;
			const {page} = item;
			if (typeof page !== 'number') return handleMissingProps();
			setSelectedLineUsingPage(page);
		},
		[setSelectedTextLine, selectedParagraphs],
	);

	const containerRef = useRef<HTMLDivElement>(null);
	type RowMatch = HTMLElement | null;

	const findRowFromContainer = (
		rowIndex: number,
		current: HTMLElement,
	): RowMatch => {
		const rows: NodeListOf<HTMLElement> =
			current.querySelectorAll(ROW_SELECTOR);
		return rows[rowIndex];
	};

	const findRow = (rowIndex: number): RowMatch => {
		const {current} = containerRef;
		/**
		 * Current might not exist if the component hasn't been rendered yet.
		 */
		if (!current) return null;
		return findRowFromContainer(rowIndex, current);
	};

	type PossibleScrollableParent = ReturnType<typeof findScrollableParent>;

	interface InfoToCalculateDistToScrollBack {
		row: HTMLElement;
		scrollOffset: number;
		scrollableParent: HTMLElement;
	}

	const getDistRowIsAheadOfScrollOffset = ({
		row,
		scrollOffset,
		scrollableParent,
	}: InfoToCalculateDistToScrollBack): number => {
		const rowBox: DOMRect = row.getBoundingClientRect();
		const parentBox: DOMRect = scrollableParent.getBoundingClientRect();
		const requiredDistFromTop: number = parentBox.top + scrollOffset;
		return rowBox.top - requiredDistFromTop;
	};

	/**
	 * We must calculate the distance to scroll back because the paragraph might
	 * already be ahead of the scroll offset, especially if we never scrolled the
	 * page when we tried scrolling the paragraph into view.
	 */
	const calculateDistToScrollBackAndScrollBack = (
		info: InfoToCalculateDistToScrollBack,
	): void => {
		const dist: number = getDistRowIsAheadOfScrollOffset(info);
		if (dist >= 0) return;
		const {scrollableParent} = info;
		scrollableParent.scrollBy(0, dist);
	};

	const handleScrollableParentBeingAWindow = (): void => {
		console.error(
			"A paragraph's first scrollable parent is a window. The scrolling functionality does not support this.",
		);
	};

	const validateParentIsElementAndScrollBack = (
		row: HTMLElement,
		scrollOffset: number,
		scrollableParent: NonNullable<PossibleScrollableParent>,
	): void => {
		// To ensure it is an HTMLElement
		if ('scrollTop' in scrollableParent)
			return calculateDistToScrollBackAndScrollBack({
				scrollableParent,
				scrollOffset,
				row,
			});
		return handleScrollableParentBeingAWindow();
	};

	const scrollBackIfNecessary = (row: HTMLElement): void => {
		/**
		 * Note that it is normal to not find the parent if the page isn't
		 * scrollable.
		 */
		const parent: PossibleScrollableParent = findScrollableParent(row);

		/**
		 * Note that even if the scroll offset is falsy because it is a zero, we
		 * shouldn't scroll.
		 */
		if (!scrollOffset || !parent) return;
		validateParentIsElementAndScrollBack(row, scrollOffset, parent);
	};

	const scrollToRow = (row: HTMLElement): void => {
		/**
		 * We scroll to the top just in case the user was looking at the next
		 * paragraph. This way, they will probably be able to see both of them. It
		 * is better to mistakenly scroll the user to a paragraph that they looked
		 * at compared to one they have not read yet.
		 */
		row.scrollIntoView(true);
		scrollBackIfNecessary(row);
	};

	const getScrollToRowIfNecessary = (rowIndex: number) => {
		return (): void => {
			const row: RowMatch = findRow(rowIndex);
			if (!row) return;
			scrollToRow(row);
		};
	};

	const scrollToRowAfterTimeout = (rowIndex: number): void => {
		const scroll = getScrollToRowIfNecessary(rowIndex);
		/**
		 * We must set a timeout. Otherwise, it won't work if the component just
		 * started rendering.
		 *
		 * Note: If we ever want to remove this, see how the RegDocDetailsPage uses
		 * this functionality to ensure the functionality continues working.
		 */
		setTimeout(scroll, 250);
	};

	React.useEffect(() => {
		const paragraphIndex = paragraphs.findIndex(
			p => p.id === scrollToParagraphId,
		);

		if (paragraphIndex !== -1) {
			scrollToRowAfterTimeout(paragraphIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scrollToParagraphId, paragraphsData]);

	const status = useContext(
		EditParagraphsFormSubmissionStatusContext,
	) as EditParagraphsFormSubmissionStatusInfo;
	const [selectionKey, setSelectionKey] = useState('initalKey');

	const generateRandomSelectionKey = () => {
		const newKey = `paragraphsList-${Math.random().toString(36).substr(2, 9)}`;
		setSelectionKey(newKey);
	};

	// Reset Paragraphs selection after submitting the metadata form
	React.useEffect(() => {
		if (!status || !status.isSubmitting) return;
		setSelectedParagraphs([]);
		generateRandomSelectionKey();
	}, [status, setSelectedParagraphs]);

	return (
		<div ref={containerRef}>
			<RegDocParagraphTooltipTranslationProvider>
				<ParagraphsListContextProvider>
					<ViewPDFDialog />
					<EntityContextProvider>
						<EntityList
							{...props}
							sticky={sticky}
							listId={listId}
							items={paragraphsData}
							columns={columns}
							aria-rowcount
							selectionMode={
								props.selectionMode ?? (selectionMode || SelectionMode.multiple)
							}
							// Feature: setKey='paragraphsList' enable this key in order to keep the paragraphs selection after edit
							onRenderRow={onRenderRow}
							selection={selection}
							onItemInvoked={onItemInvoked}
							onActiveItemChanged={onActiveItemChanged}
							initialFocusedIndex={initialFocusedIndex}
							onShouldVirtualize={() => true}
							getKey={getKey}
							key={selectionKey}
						/>
						{!fromRequirements && <FaqCommand />}
					</EntityContextProvider>
				</ParagraphsListContextProvider>
			</RegDocParagraphTooltipTranslationProvider>
		</div>
	);
};

const renderKeywordAssignments = (item: ParagraphOfParagraphsList) => {
	return (
		<ParagraphsListKeywordAssignments
			keywordAssignments={item.keywordAssignments ?? []}
			limit={2}
		/>
	);
};
