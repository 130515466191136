import React from 'react';
import {TFunction} from 'i18next';
import {KeywordStatus} from 'types';

export class ParagraphsListHelper {
	static getKeywordAssignmentTooltipContent = (
		keywordName: string,
		vexClusterName: string,
		keywordStatus: KeywordStatus,
		tParagraphsList: TFunction,
		tStatusTooltip: TFunction,
	) => {
		return (
			<>
				<span>
					{ParagraphsListHelper.getKeywordAssignmentTooltipLabel(
						keywordName,
						vexClusterName,
						keywordStatus,
						tParagraphsList,
					)}
				</span>
				<br />
				<span>
					{ParagraphsListHelper.getKeywordAssignmentTooltipComment(
						keywordStatus,
						tStatusTooltip,
					)}
				</span>
			</>
		);
	};

	static getKeywordAssignmentTooltipComment = (
		status: KeywordStatus,
		t: TFunction,
	) => {
		switch (status) {
			case KeywordStatus.New:
				return t('StatusNew');
			case KeywordStatus.Declined:
			case KeywordStatus.NoImpact:
				return t('StatusDeclined');
			case KeywordStatus.Accepted:
				return t('StatusAccepted');
			case KeywordStatus.Finalized:
				return t('StatusFinalized');
			case KeywordStatus.NoRequirements:
			case KeywordStatus.RequirementsDerived:
			default:
				return '';
		}
	};

	static getKeywordAssignmentTooltipLabel = (
		keywordName: string,
		vexClusterName: string,
		keywordStatus: KeywordStatus,
		t: TFunction,
	) => {
		return `${keywordName} | ${vexClusterName} | ${t(keywordStatus)}`;
	};
}
