import * as Types from '../types/types.generated';

import { gql } from '@apollo/client';
import { AttachmentFieldsFragmentDoc } from './attachmentFields.generated';
import { KeywordTagFieldsFragmentDoc } from './keywordTagFields.generated';
export type RequirementListRequirementFieldsFragment = { __typename?: 'Requirement', id: string, name: string, requirementId?: string | null, status: Types.RequirementStatus, category: Types.RequirementCategory, definition?: string | null, modifiedAt?: any | null, version?: string | null, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, systemLevels: Array<{ __typename?: 'SystemLevel', id: string, name: string }>, markets: Array<{ __typename?: 'Market', id: string, name: string }>, createdBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, modifiedBy: { __typename?: 'User', givenName?: string | null, id: string, mail?: string | null, name: string, surname?: string | null, userPrincipalName?: string | null }, successor?: { __typename?: 'Requirement', id: string, name: string } | null, childrenRefs?: Array<{ __typename?: 'Requirement', id: string, version?: string | null }> | null, tags: Array<{ __typename?: 'Tag', id: string, name: string }>, attachments?: Array<{ __typename?: 'AttachmentRef', attachmentId: string, isVkoOnly?: boolean | null, file: { __typename?: 'BlobRef', uri: any, contentType: string, fileName: string, originalFileName?: string | null }, category?: { __typename?: 'AttachmentCategory', id: string, name: string } | null }> | null, keywords: Array<{ __typename?: 'Keyword', id: string, name: string, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }>, vkos: Array<{ __typename?: 'User', id: string, name: string }> }>, documentReferences?: Array<{ __typename?: 'DocumentReference', reference?: { __typename?: 'Reference', name: string } | null, requirementReferences: Array<{ __typename?: 'Requirement', id: string, name: string }> }> | null };

export const RequirementListRequirementFieldsFragmentDoc = gql`
    fragment RequirementListRequirementFields on Requirement {
  id
  name
  requirementId
  vexClusters {
    id
    name
  }
  status
  category
  definition
  systemLevels {
    id
    name
  }
  markets {
    id
    name
  }
  createdBy {
    givenName
    id
    mail
    name
    surname
    userPrincipalName
  }
  modifiedAt
  version
  modifiedBy {
    givenName
    id
    mail
    name
    surname
    userPrincipalName
  }
  successor {
    id
    name
  }
  childrenRefs {
    id
    version
  }
  tags {
    id
    name
  }
  attachments {
    ...AttachmentFields
  }
  keywords {
    ...KeywordTagFields
  }
  documentReferences {
    reference {
      name
    }
    requirementReferences {
      id
      name
    }
  }
}
    ${AttachmentFieldsFragmentDoc}
${KeywordTagFieldsFragmentDoc}`;