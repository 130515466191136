import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseUploadRegulatoryDocumentWithAttachmentsMutationVariables = Types.Exact<{
  input: Types.UploadRegulatoryDocumentWithAttachmentsInput;
}>;


export type UseUploadRegulatoryDocumentWithAttachmentsMutation = { __typename?: 'Mutation', uploadRegulatoryDocumentWithAttachments: { __typename?: 'RegulatoryDocumentFilePayloadWithAttachments', regulatoryDocumentFileWithAttachments?: { __typename?: 'BlobRef', contentType: string, fileName: string, uri: any } | null, errors?: Array<{ __typename?: 'DoubletError', message: string }> | null } };


export const UseUploadRegulatoryDocumentWithAttachmentsDocument = gql`
    mutation useUploadRegulatoryDocumentWithAttachments($input: UploadRegulatoryDocumentWithAttachmentsInput!) {
  uploadRegulatoryDocumentWithAttachments(input: $input) {
    regulatoryDocumentFileWithAttachments {
      contentType
      fileName
      uri
    }
    errors {
      ... on Error {
        message
      }
    }
  }
}
    `;
export type UseUploadRegulatoryDocumentWithAttachmentsMutationFn = Apollo.MutationFunction<UseUploadRegulatoryDocumentWithAttachmentsMutation, UseUploadRegulatoryDocumentWithAttachmentsMutationVariables>;

/**
 * __useUseUploadRegulatoryDocumentWithAttachmentsMutation__
 *
 * To run a mutation, you first call `useUseUploadRegulatoryDocumentWithAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseUploadRegulatoryDocumentWithAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useUploadRegulatoryDocumentWithAttachmentsMutation, { data, loading, error }] = useUseUploadRegulatoryDocumentWithAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUseUploadRegulatoryDocumentWithAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<UseUploadRegulatoryDocumentWithAttachmentsMutation, UseUploadRegulatoryDocumentWithAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseUploadRegulatoryDocumentWithAttachmentsMutation, UseUploadRegulatoryDocumentWithAttachmentsMutationVariables>(UseUploadRegulatoryDocumentWithAttachmentsDocument, options);
      }
export type UseUploadRegulatoryDocumentWithAttachmentsMutationHookResult = ReturnType<typeof useUseUploadRegulatoryDocumentWithAttachmentsMutation>;
export type UseUploadRegulatoryDocumentWithAttachmentsMutationResult = Apollo.MutationResult<UseUploadRegulatoryDocumentWithAttachmentsMutation>;
export type UseUploadRegulatoryDocumentWithAttachmentsMutationOptions = Apollo.BaseMutationOptions<UseUploadRegulatoryDocumentWithAttachmentsMutation, UseUploadRegulatoryDocumentWithAttachmentsMutationVariables>;