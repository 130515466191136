import * as Types from '../../../types/types.generated';

import { gql } from '@apollo/client';
import { FieldsForRequirementColumnFragmentDoc } from '../../../fragments/ColumnRenderers.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRequirementTooltipDetailsQueryVariables = Types.Exact<{
  requirementId: Types.Scalars['ID'];
}>;


export type GetRequirementTooltipDetailsQuery = { __typename?: 'Query', requirement?: { __typename?: 'Requirement', category: Types.RequirementCategory, version?: string | null, id: string, status: Types.RequirementStatus, name: string, requirementId?: string | null, definition?: string | null, markets: Array<{ __typename?: 'Market', id: string, name: string }>, vexClusters: Array<{ __typename?: 'VexCluster', id: string, name: string }> } | null };


export const GetRequirementTooltipDetailsDocument = gql`
    query GetRequirementTooltipDetails($requirementId: ID!) {
  requirement(id: $requirementId) {
    ...FieldsForRequirementColumn
    category
    version
    markets {
      id
      name
    }
  }
}
    ${FieldsForRequirementColumnFragmentDoc}`;

/**
 * __useGetRequirementTooltipDetailsQuery__
 *
 * To run a query within a React component, call `useGetRequirementTooltipDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequirementTooltipDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequirementTooltipDetailsQuery({
 *   variables: {
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useGetRequirementTooltipDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetRequirementTooltipDetailsQuery, GetRequirementTooltipDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequirementTooltipDetailsQuery, GetRequirementTooltipDetailsQueryVariables>(GetRequirementTooltipDetailsDocument, options);
      }
export function useGetRequirementTooltipDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequirementTooltipDetailsQuery, GetRequirementTooltipDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequirementTooltipDetailsQuery, GetRequirementTooltipDetailsQueryVariables>(GetRequirementTooltipDetailsDocument, options);
        }
export type GetRequirementTooltipDetailsQueryHookResult = ReturnType<typeof useGetRequirementTooltipDetailsQuery>;
export type GetRequirementTooltipDetailsLazyQueryHookResult = ReturnType<typeof useGetRequirementTooltipDetailsLazyQuery>;
export type GetRequirementTooltipDetailsQueryResult = Apollo.QueryResult<GetRequirementTooltipDetailsQuery, GetRequirementTooltipDetailsQueryVariables>;