import {mergeStyles, ScrollablePane, SelectionMode} from '@fluentui/react';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {ConfirmDeleteDialog, useDialogState} from 'components/Dialogs';
import {useCommand, useSelection} from 'hooks';
import React from 'react';
import {useEntityContext} from './EntityContext';
import {UserRole} from 'types';
import {
	ExtraFieldsForNameColumn,
	useEntityNameColumn,
} from './useEntityNameColumn';
import {useEditEntityCmd} from 'hooks/useEditEntityCmd';
import {EntityFormPanels, EntityFormPanelsProps} from './EntityFormPanels';
import {EntityOfFormPanels} from './EntityFormPanels.types';
import {
	StateForEntityFormPanels,
	useStateForEntityFormPanels,
	useEntityPageTranslation,
} from './hooksForEntityFormPanels';
import {FormMode} from './EntityPage.types';

type KeysToOmitFromPanelProps<T extends EntityOfFormPanels> =
	| 'performExtraTaskOnCancellation'
	| 'infoOfUpdateDialog'
	| 'entity'
	| keyof StateForEntityFormPanels<T>;

/**
 * * Note
 *
 * Use propsOfEntityFormPanel from now on because we might remove this in the
 * future.
 */
type PropsFromPanels<T extends EntityOfFormPanels> = Omit<
	EntityFormPanelsProps<T>,
	KeysToOmitFromPanelProps<T>
>;

export interface EntityPageProps<T extends EntityOfFormPanels>
	extends PropsFromPanels<T> {
	items: T[];
	deleteEntity: (id: string) => any;
	additionalColumns?: EntityListColumn[];
	onViewItem?: (item: T) => void;
	disableDelete?: (selectedItem: T) => boolean;
	disableEdit?: (selectedItem: T) => boolean;
	onShouldVirtualize?: () => boolean;
	groupListByFieldName?: string;
	hideCreate?: boolean;
	hideDelete?: boolean;
	hideEdit?: boolean;
	rolesCreate?: UserRole[];
	rolesEdit?: UserRole[];
	rolesDelete?: UserRole[];
	/**
	 * If your page has content above the Entity Page, you might need to set this
	 * to false in some cases to prevent a double vertical scrollbar.
	 */
	shouldWrapListWithScrollablePane?: boolean;
	entityListProps?: Partial<EntityListProps>;
	propsOfEntityFormPanel?: Partial<EntityFormPanelsProps<T>>;
	withNameColumnInPanel?: boolean;
	extraFieldsForNameColumn?: ExtraFieldsForNameColumn;
	className?: string;
}

const userRoles = [
	UserRole.SystemAdministrator,
	UserRole.ServiceProvider,
	UserRole.Vex,
	UserRole.Vko,
	UserRole.VkoExternal,
	UserRole.ShApprover,
	UserRole.Readers,
	UserRole.RegulationReader,
];

/**
 * @see EntityList for some important notes about using this component.
 */
export function EntityPage<T extends EntityOfFormPanels>(
	props: EntityPageProps<T>,
) {
	const {t} = useEntityPageTranslation();
	const {
		items,
		entityDisplayName,
		deleteEntity,
		additionalColumns = [],
		onViewItem,
		disableDelete = () => false,
		disableEdit = () => false,
		onShouldVirtualize = () => false,
		withNameColumn = true,
		withNameColumnInPanel,
		groupListByFieldName,
		hideCreate = false,
		hideDelete = false,
		hideEdit = false,
		rolesCreate = userRoles,
		rolesEdit = userRoles,
		rolesDelete = userRoles,
		shouldWrapListWithScrollablePane = true,
		entityListProps,
		extraFieldsForNameColumn,
		propsOfEntityFormPanel,
		className,
		...other
	} = props;

	const stateForPanels: StateForEntityFormPanels<T> =
		useStateForEntityFormPanels();
	const {
		modeInfo: [_mode, setMode],
		formInfo: {reset},
	} = stateForPanels;

	const [selectedItem, setSelectedItem] = React.useState<T | undefined>();
	const {setSelectedItems} = useEntityContext<T>();
	const [selection] = useSelection<T>({
		onSelectionChanged(selectedItems) {
			setSelectedItem(selectedItems[0]);
			setSelectedItems?.(selectedItems);
		},
	});
	const [deleteDialogState, showDeleteDialog, hideDeleteDialog] =
		useDialogState();

	const handleDeleteClick = React.useCallback(() => {
		if (selectedItem) {
			deleteEntity(selectedItem.id);
		}

		hideDeleteDialog();
	}, [selectedItem, hideDeleteDialog]);

	useCommand(
		{
			key: 'view',
			text: t('View'),
			iconProps: {iconName: 'View'},
			priority: 1,
			disabled: !selectedItem,
			hidden: !onViewItem,
			onClick: () => selectedItem && onViewItem?.(selectedItem),
		},
		[selectedItem],
	);
	useCommand(
		{
			key: 'new',
			text: t('New'),
			iconProps: {iconName: 'Add'},
			priority: 2,
			disabled: Boolean(selectedItem),
			onClick() {
				reset({} as any);
				setMode(FormMode.Create);
			},
			hidden: hideCreate,
			roles: rolesCreate,
		},
		[selectedItem, hideCreate, rolesCreate],
	);

	useEditEntityCmd(
		{
			priority: 3,
			disabled: !selectedItem || disableEdit(selectedItem),
			key: 'edit',
			onClick() {
				reset(selectedItem ?? ({} as any));
				// eslint-disable-next-line no-unused-expressions
				(selectedItem as any).status === 'FINAL'
					? setMode(FormMode.Create)
					: setMode(FormMode.Update);
			},
			hidden: hideEdit,
			roles: rolesEdit,
		},
		[selectedItem, hideEdit, rolesEdit],
	);

	useCommand(
		{
			key: 'delete',
			text: t('Delete'),
			iconProps: {iconName: 'Delete'},
			priority: 4,
			disabled: !selectedItem || disableDelete(selectedItem),
			onClick() {
				showDeleteDialog();
			},
			hidden: hideDelete,
			roles: rolesDelete,
		},
		[selectedItem, hideDelete, rolesDelete],
	);

	const nameColumn: EntityListColumn = useEntityNameColumn(
		entityDisplayName,
		extraFieldsForNameColumn,
	);

	const columns: EntityListColumn[] = React.useMemo(
		() =>
			withNameColumn
				? [nameColumn, ...additionalColumns]
				: [...additionalColumns],
		[],
	);

	const onItemInvoked = React.useCallback((item: T) => onViewItem?.(item), []);

	const renderEntityList = (): JSX.Element => {
		return (
			<EntityList
				items={items}
				columns={columns}
				selectionMode={SelectionMode.single}
				selection={selection}
				onItemInvoked={onItemInvoked}
				onShouldVirtualize={onShouldVirtualize}
				groupByFieldName={groupListByFieldName}
				sticky={entityListProps?.sticky ?? true}
				{...entityListProps}
			/>
		);
	};

	const renderListWithScrollablePaneIfNecessary = (): JSX.Element => {
		const list: JSX.Element = renderEntityList();
		if (shouldWrapListWithScrollablePane)
			return <ScrollablePane>{list}</ScrollablePane>;
		return list;
	};

	const list: JSX.Element = renderListWithScrollablePaneIfNecessary();

	const getClassName = (): string => {
		return mergeStyles([wrapperClassName, className]);
	};

	return (
		<div className={getClassName()}>
			{list}
			<EntityFormPanels
				entityDisplayName={entityDisplayName}
				entity={selectedItem}
				performExtraTaskOnCancellation={hideDeleteDialog}
				withNameColumn={withNameColumnInPanel ?? withNameColumn}
				{...stateForPanels}
				{...other}
				{...propsOfEntityFormPanel}
			/>
			<ConfirmDeleteDialog
				{...deleteDialogState}
				onConfirm={handleDeleteClick}
			/>
		</div>
	);
}

const wrapperClassName = mergeStyles({
	height: '100%',
	position: 'relative',
});
