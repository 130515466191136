import {UserRole} from 'types';
import {LoadWrapper} from 'components/LoadWrapper';
import {ViewAuthorizer} from 'components/ViewAuthorizer';
import React from 'react';
import {RegulationsPageRegulationsForm, RegulationsList} from './components';
import {RegulationsContextProvider} from './context';
import {useGetAllRegulationsQuery} from './hooks';
import {useTranslation} from 'react-i18next';
import {REGULATIONS_TRANSLATION_NAMESPACE} from './regulationsConstants';
import {HeaderForPagesWithEntityPage} from 'components/HeaderForPagesWithEntityPage';

const RegulationsPage = () => {
	const {t} = useTranslation(REGULATIONS_TRANSLATION_NAMESPACE, {
		keyPrefix: 'RegulationDetail',
	});

	const {loading, data} = useGetAllRegulationsQuery();
	const regulations = React.useMemo(() => data?.regulations || [], [data]);

	return (
		<ViewAuthorizer
			roles={[
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.Vex,
				UserRole.VkoExternal,
				UserRole.ShApprover,
				UserRole.Readers,
				UserRole.RegulationReader,
				UserRole.Bedarfskoordinator,
			]}
		>
			<RegulationsContextProvider>
				<HeaderForPagesWithEntityPage>
					<h3>{t('Regulations')}</h3>
				</HeaderForPagesWithEntityPage>
				<LoadWrapper loading={loading}>
					<RegulationsList regulations={regulations} sticky={true} />
					<RegulationsPageRegulationsForm />
				</LoadWrapper>
			</RegulationsContextProvider>
		</ViewAuthorizer>
	);
};

export default RegulationsPage;
