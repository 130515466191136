import {
	Callout,
	FontWeights,
	mergeStyleSets,
	Text,
	Stack,
	Persona,
} from '@fluentui/react';
import * as React from 'react';
import {logout} from 'authentication';
import {useTranslation} from 'react-i18next';
import {useBoolean, useId} from '@fluentui/react-hooks';
import {useMsal} from '@azure/msal-react';
import {useUserContext} from 'authentication/UserContext';

export const UserSettings: React.FC<any> = () => {
	const {t} = useTranslation('components/usersettings', {
		keyPrefix: 'UserSettings',
	});
	const msal = useMsal();
	const buttonId = useId('callout-button');

	const userName = React.useMemo(
		() => msal.instance.getActiveAccount()?.name,
		[],
	);
	const [isCalloutVisible, {toggle: toggleIsCalloutVisible}] =
		useBoolean(false);

	const {
		isVex,
		isAdmin,
		isServiceProvider,
		isVko,
		isVkoExternal,
		isApprover,
		isReader,
		isBedarfskoordinator,
		isRegulationReader,
	} = useUserContext();

	const role = React.useMemo(() => {
		if (isVex) return 'VEX';

		if (isAdmin) return 'System Administrator';

		if (isServiceProvider) return 'Service Provider';

		if (isVko) return 'VKO';

		if (isVkoExternal) return 'VKO External';

		if (isApprover) return 'Approver';

		if (isReader) return 'Reader';

		if (isBedarfskoordinator) return 'Bedarfskoordinator';

		if (isRegulationReader) return 'Regulation Reader';
	}, [
		isVex,
		isAdmin,
		isServiceProvider,
		isVko,
		isVkoExternal,
		isApprover,
		isReader,
		isBedarfskoordinator,
		isRegulationReader,
	]);

	return (
		<>
			<Persona
				aria-label='persona'
				id={buttonId}
				text={userName}
				onClick={toggleIsCalloutVisible}
				hidePersonaDetails={true}
				className={styles.userpersonaAppBar}
			/>
			{isCalloutVisible ? (
				<Callout
					ariaLabel='callout'
					className={styles.callout}
					isBeakVisible={false}
					role='dialog'
					target={`#${buttonId}`}
					onDismiss={toggleIsCalloutVisible}
				>
					<Stack horizontal horizontalAlign='space-between'>
						<Stack.Item>
							<Text block variant='xSmall' className={styles.title}>
								Porsche
							</Text>
						</Stack.Item>
						<Stack.Item>
							<Text
								block
								variant='xSmall'
								className={styles.logout}
								onClick={() => logout(msal.instance)}
							>
								{t('SignOut')}
							</Text>
						</Stack.Item>
					</Stack>
					<Stack horizontalAlign='start'>
						<Stack.Item>
							<Persona
								className={styles.userpersonasettings}
								text={userName}
								secondaryText={role}
							/>
						</Stack.Item>
					</Stack>
				</Callout>
			) : null}
		</>
	);
};

const styles = mergeStyleSets({
	button: {
		width: 130,
	},
	callout: {
		paddingTop: '12px',
		width: 320,
		maxWidth: '90%',
		height: '130px',
	},
	title: {
		padding: '10px',
		marginBottom: 12,
		fontWeight: FontWeights.semilight,
	},
	logout: {
		padding: '10px',
		marginBottom: 12,
		fontWeight: FontWeights.semilight,
		cursor: 'pointer',
	},
	userpersonasettings: {
		paddingLeft: '10px',
	},
	userpersonaAppBar: {
		cursor: 'pointer',
	},
});
