import {
	DirectionalHint,
	mergeStyleSets,
	TooltipHost,
	useTheme,
} from '@fluentui/react';
import {useUserContext} from 'authentication/UserContext';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Keyword, KeywordStatus, VexCluster, VexClusterAssignment} from 'types';
import {ParagraphsListHelper} from './EditParagraphsForm/ParagraphsListHelper';
import {Text} from '@fluentui/react';

type VexClusterOfVexClusterAssignment = Pick<VexCluster, 'id' | 'name'>;

export interface VexClusterAssignmentOfKeywordAssignment
	extends Pick<VexClusterAssignment, 'keywordStatus'> {
	vexCluster: VexClusterOfVexClusterAssignment;
}

export interface VexClusterAssignmentOfKeywordAssignmentExtended
	extends VexClusterAssignmentOfKeywordAssignment {
	keyword: KeywordOfKeywordAssignment;
}

export type KeywordOfKeywordAssignment = Pick<Keyword, 'id' | 'name'>;

export interface ParagraphsListKeywordAssignment {
	keyword: KeywordOfKeywordAssignment;
	vexClusterAssignments: VexClusterAssignmentOfKeywordAssignment[];
}

const KeywordAssignmentItem: React.FC<{
	keyword: KeywordOfKeywordAssignment;
	vexClusterAssignment: VexClusterAssignmentOfKeywordAssignment;
}> = ({keyword, vexClusterAssignment}) => {
	const theme = useTheme();
	const {myKeywords, myVexClusters} = useUserContext();

	const defaultStyle = {
		color: theme.palette.neutralSecondary,
		background: theme.palette.neutralLight,
	};

	const stylesByKeywordAssignmentStatus = {
		[KeywordStatus.Accepted]: {
			color: theme.palette.white,
			background: theme.palette.green,
		},
		[KeywordStatus.Declined]: {
			color: theme.palette.white,
			background: theme.palette.red,
		},
		[KeywordStatus.NoImpact]: {
			color: theme.palette.white,
			background: theme.palette.red,
		},
		[KeywordStatus.Finalized]: {
			color: theme.palette.white,
			background: theme.palette.greenLight,
		},
		[KeywordStatus.RequirementsDerived]: {
			color: theme.palette.white,
			background: theme.palette.greenDark,
		},
		[KeywordStatus.New]: defaultStyle,
		[KeywordStatus.NoRequirements]: defaultStyle,
	};

	const isMyAssignedKeywordAssignment = React.useMemo(() => {
		return (
			myKeywords.some(mk => mk.id === keyword.id) &&
			myVexClusters.some(vc => vc.id === vexClusterAssignment.vexCluster.id)
		);
	}, [myKeywords, myVexClusters, keyword, vexClusterAssignment]);

	return (
		<div
			key={vexClusterAssignment.vexCluster.id}
			style={{
				border: isMyAssignedKeywordAssignment ? '1px solid black' : undefined,
				borderRadius: '10px',
				fontSize: 12,
				padding: '2px 7px',
				marginRight: 5,
				marginBottom: 10,
				whiteSpace: 'normal',
				width: '150px',
				...stylesByKeywordAssignmentStatus[vexClusterAssignment.keywordStatus],
			}}
		>
			{vexClusterAssignment.vexCluster.name}
		</div>
	);
};

export const ParagraphsListKeywordAssignments: React.FC<{
	keywordAssignments: ParagraphsListKeywordAssignment[];
	limit?: number;
}> = ({keywordAssignments, limit}) => {
	const [showAll, setShowAll] = useState(false);
	const [limitedAssignments, setLimitedAssignments] = useState<
		VexClusterAssignmentOfKeywordAssignmentExtended[]
	>([]);
	const [diff, setDiff] = useState(0);

	const {myVexClusters} = useUserContext();
	const {t: tStatusTooltip} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'StatusTooltip',
	});
	const {t: tParagraphsList} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphsList',
	});

	const theme = useTheme();
	const classNames = mergeStyleSets({
		arrayItem: {
			color: theme?.palette.neutralSecondary,
			background: theme?.palette.neutralLight,
			borderRadius: '10px',
			fontSize: 12,
			padding: '2px 7px',
			marginRight: 5,
			marginBottom: 10,
			whiteSpace: 'nowrap',
		},
		arrayButton: {
			cursor: 'pointer',
		},
	});

	const countVexClusterAssignments = useCallback(
		() =>
			keywordAssignments.reduce(
				(acc, curr) => acc + curr.vexClusterAssignments.length,
				0,
			),
		[keywordAssignments],
	);

	React.useEffect(() => {
		if (!limit) {
			setShowAll(true);
			return;
		}
		const count = countVexClusterAssignments();
		setShowAll(limit >= count);
	}, [limit, keywordAssignments, countVexClusterAssignments]);

	React.useEffect(() => {
		const flatList = keywordAssignments
			.flatMap(ka =>
				ka.vexClusterAssignments.map(vca => {
					return {
						...vca,
						keyword: ka.keyword,
					};
				}),
			)
			.sort(v =>
				myVexClusters.some(myVC => myVC.id === v.vexCluster.id) ? 1 : -1,
			);
		if (!limit || showAll) {
			setLimitedAssignments(flatList);
			return;
		}
		const count = countVexClusterAssignments();
		if (limit < count) {
			setDiff(count - limit);
			setLimitedAssignments(flatList.slice(0, limit));
		} else {
			setLimitedAssignments(flatList);
		}
	}, [
		limit,
		keywordAssignments,
		showAll,
		countVexClusterAssignments,
		myVexClusters,
	]);

	return (
		<React.Fragment>
			{limitedAssignments.map(v => (
				<TooltipHost
					key={`${v.keyword.id}${v.vexCluster.id}`}
					content={ParagraphsListHelper.getKeywordAssignmentTooltipContent(
						v.keyword.name,
						v.vexCluster.name,
						v.keywordStatus,
						tParagraphsList,
						tStatusTooltip,
					)}
					directionalHint={DirectionalHint.rightCenter}
				>
					<KeywordAssignmentItem
						key={`${v.keyword.id}${v.vexCluster.id}`}
						keyword={v.keyword}
						vexClusterAssignment={v}
					/>
				</TooltipHost>
			))}
			{!showAll && (
				<Text
					onClick={() => setShowAll(true)}
					className={`${classNames.arrayItem} ${classNames.arrayButton}`}
				>{`+ ${diff}`}</Text>
			)}
		</React.Fragment>
	);
};
