import {
	Checkbox,
	CheckboxVisibility,
	CommandButton,
	ConstrainMode,
	ContextualMenuItemType,
	DefaultButton,
	DetailsList,
	IBasePicker,
	IBasePickerSuggestionsProps,
	ICheckboxStyles,
	IColumn,
	IconButton,
	IDetailsHeaderProps,
	IDetailsList,
	IDetailsListProps,
	IDetailsListStyles,
	IGroup,
	IIconProps,
	IPanelStyles,
	IRefObject,
	IRenderFunction,
	ISelection,
	IStackItemStyles,
	ITag,
	ITagPickerProps,
	MarqueeSelection,
	Panel,
	PanelType,
	PrimaryButton,
	SelectionMode,
	Separator,
	Stack,
	TagPicker,
	TextField,
} from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import {getRendererForHeaderWithTooltips} from '../../features/localizedTooltips/componentsWithTooltips/HeaderWithTooltipsAndStickySupport';
import {ColumnWithTooltipOptions} from 'features/localizedTooltips';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {useCommand} from 'hooks';
import {createSearchParams, useNavigate} from 'react-router-dom';
import {InfinityListColumn, InfinityListFilter} from './InfinityList';

type FilterItemProps = {
	col: InfinityListColumn;
	allFilters: InfinityListFilter[];
	setAllFilters: (allFilters: InfinityListFilter[]) => void;
};

export const FilterItem: React.FC<FilterItemProps> = ({
	col,
	allFilters,
	setAllFilters,
}) => {
	const {
		name: columnName,
		key: columnKey,
		filterOnFilter,
		fieldValuesUnique,
	} = col;

	const [isExpanded, {toggle: toggleExpanded}] = useBoolean(false);

	const [limitCheckboxes, setLimitChechboxes] = useState(50);

	const [searchedOptions, setSearchedOptions] = useState<string[]>([]);

	const refTagPicker = useRef<IBasePicker<ITag>>(null);

	const valuesUnique = fieldValuesUnique ?? [];

	const checkboxes = useMemo(() => {
		const shownOptions = valuesUnique
			.filter(x => !searchedOptions.includes(x))
			.slice(0, limitCheckboxes);

		const selectedOptions = allFilters
			.filter(x => x.columnKey === columnKey)
			.map(x => x.selection);

		const hiddenSelectedOptions = selectedOptions.filter(
			x => !(shownOptions.includes(x) || searchedOptions.includes(x)),
		);
		const checkboxOptions = [
			...searchedOptions,
			...hiddenSelectedOptions,
			...shownOptions,
		];

		return checkboxOptions.map((val, j) => (
			<Checkbox
				key={uuidv4()}
				label={val}
				defaultChecked={selectedOptions.includes(val)}
				onChange={(_, checked) => {
					if (checked) {
						const newSelection: InfinityListFilter = {
							columnKey,
							selection: val,
						};
						setAllFilters([...allFilters, newSelection]);
					} else {
						setAllFilters(
							allFilters.filter(
								x => !(x.columnKey === columnKey && x.selection === val),
							),
						);
					}
				}}
			/>
		));
	}, [valuesUnique, allFilters, limitCheckboxes, searchedOptions]);

	const numHiddenOptions = valuesUnique.length - checkboxes.length;

	const onResolveSuggestions = (
		filterText: string,
		selectedItems?: ITag[],
	): ITag[] => {
		if (!filterText) {
			return [];
		}
		const reFilter = new RegExp((filterText ?? '').toLowerCase());

		const fieldValueSuggestions = valuesUnique.filter(x =>
			reFilter.test(x.toLowerCase()),
		);

		const suggestions: ITag[] = (fieldValueSuggestions ?? [])
			.slice(0, 100)
			.map((val, j) => ({
				key: val,
				name: val,
			}));

		return suggestions;
	};

	return (
		<Stack tokens={{childrenGap: 8}}>
			<CommandButton
				iconProps={{iconName: isExpanded ? 'ChevronUp' : 'ChevronDown'}}
				text={columnName}
				onClick={() => toggleExpanded()}
				ariaLabel={`Toggle filter ${columnName}`}
			/>

			<Stack.Item
				styles={{
					root: {
						display: isExpanded ? 'block' : 'none',
					},
				}}
			>
				<>
					{filterOnFilter && (
						<>
							<TagPicker
								onResolveSuggestions={onResolveSuggestions}
								pickerSuggestionsProps={{
									suggestionsHeaderText: columnName,
									noResultsFoundText: 'Kein Suchergebnis',
								}}
								onItemSelected={selectedItem => {
									const newSelection: InfinityListFilter = {
										columnKey,
										selection: selectedItem?.name ?? '',
									};
									setAllFilters([...allFilters, newSelection]);
									setSearchedOptions(prev => [
										...prev,
										selectedItem?.name ?? '',
									]);
									return null;
								}}
								inputProps={{
									placeholder: 'Suchen',
								}}
								componentRef={refTagPicker}
							/>
							<br />
						</>
					)}
					{<Stack tokens={{childrenGap: 4}}>{checkboxes}</Stack>}
					{valuesUnique.length > limitCheckboxes && (
						<div style={{margin: 10}}>
							{
								<Stack horizontal horizontalAlign='space-between'>
									<div>
										<IconButton
											iconProps={{iconName: 'DoubleChevronDown'}}
											onClick={() => {
												setLimitChechboxes(
													limitCheckboxes < 200
														? limitCheckboxes + 100
														: limitCheckboxes + 500,
												);
											}}
										/>
										<i>{`...${numHiddenOptions} weitere Optionen`}</i>
									</div>
									<IconButton
										iconProps={{iconName: 'DoubleChevronUp'}}
										onClick={() => {
											refTagPicker.current?.focus();
											setLimitChechboxes(50);
										}}
									/>
								</Stack>
							}
						</div>
					)}
				</>
			</Stack.Item>
		</Stack>
	);
};
