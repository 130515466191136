import {DefaultButton, Panel, PanelType, PrimaryButton} from '@fluentui/react';

import {useTranslation} from 'react-i18next';
import {useStore} from 'react-context-hook';
import React, {useState, useEffect} from 'react';
import {useEntityContext} from 'components/EntityPage/EntityContext';
import _ from 'lodash';
import {CreateQuestionDialog} from './CreateQuestionDialog';
import {FaqPanelContent} from './FaqPanelContent';
import {useQuestionsLazyQuery} from './Faq.generated';

export const FaqPanel: any = () => {
	const [panel, setPanel] = useStore('panel', false);
	const {selectedItems} = useEntityContext<any>();

	const {t} = useTranslation('common/faq');

	const [showQuestionDialog, setShowQuestionDialog] = useState(false);

	const [selectedItem, setSelectedItem] = useState(undefined);

	const [questions, setQuestions] = useState(undefined);

	const [fetchQuestions, {data}] = useQuestionsLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (selectedItems && selectedItems.length > 0 && panel) {
			setSelectedItem(selectedItems[0]);
			fetchQuestions({
				variables: {
					targetRecordId: selectedItems[0].id,
				},
			});
		}
	}, [selectedItems, panel]);

	useEffect(() => {
		if (data) {
			setQuestions((data as any)?.questions); // Save the fetched data in state
		}
	}, [data]); // Update state whenever queryData changes

	const closePanel = () => {
		setPanel(false);
		setQuestions(undefined);
	};

	const onRenderFooterContent: any = () => (
		<form>
			<PrimaryButton
				style={{marginRight: '10px'}}
				onClick={() => setShowQuestionDialog(true)}
			>
				{t('PostQuestionButton')}
			</PrimaryButton>
			<DefaultButton text={t('Cancel')} onClick={closePanel} />
		</form>
	);

	return (
		<Panel
			type={PanelType.large}
			isLightDismiss
			isOpen={panel}
			onDismiss={closePanel}
			closeButtonAriaLabel='Close'
			headerText={t('Header')}
			onRenderFooterContent={onRenderFooterContent}
			isFooterAtBottom={true}
			styles={{
				commands: {background: 'white !important'},
				content: {overflowY: 'hidden'},

				footer: {background: 'white !important'},
			}}
		>
			{panel && (
				<>
					<FaqPanelContent questions={questions} />
					<CreateQuestionDialog
						showDialog={showQuestionDialog}
						setShowDialog={setShowQuestionDialog}
					/>
				</>
			)}
		</Panel>
	);
};
