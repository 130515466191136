import {AccountInfo, AuthenticationResult} from '@azure/msal-browser';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import React from 'react';
import {Keyword, TableConfiguration, VexCluster, UserRole} from 'types';
import {useMyFavoritesIdsOnlyQuery} from './hooks/myFavorites.generated';
import {useGetMyVexClustersQuery} from './hooks/myVexClusters.generated';
import {useMyTableConfigurationsQuery} from './hooks/myTableConfigurations.generated';
import {useGetMyKeywordsQuery} from './hooks/myKeywords.generated';

export type UserContextType = AccountInfo & {
	encodedUserId: string;
	roles: UserRole[];
	isAdmin: boolean;
	isVko: boolean;
	isVkoExternal: boolean;
	isVex: boolean;
	isServiceProvider: boolean;
	isApprover: boolean;
	isReader: boolean;
	isRegulationReader: boolean;
	isBedarfskoordinator: boolean;
	myVexClusters: VexCluster[];
	myKeywords: Keyword[];
	favoriteIds: {[key: string]: boolean} | undefined;
	tableConfigurations: TableConfiguration[];
};

export const UserContext = React.createContext<UserContextType>({} as any);

export const UserContextProvider: React.FC<{
	authResult: AuthenticationResult | null;
	children: React.ReactNode;
}> = ({authResult, children}) => {
	let account = {} as AccountInfo;
	let roles = [] as UserRole[];
	let encodedUserId = '';

	if (authResult && authResult.account) {
		const decoded = jwtDecode<JwtPayload & {[key: string]: any}>(
			authResult.accessToken,
		);
		account = authResult.account;
		roles = decoded.roles || [];
		encodedUserId = encodeUserId(authResult.account.localAccountId);
	}

	const isAdmin = roles.includes(UserRole.SystemAdministrator);
	const isVko = roles.includes(UserRole.Vko);
	const isVkoExternal = roles.includes(UserRole.VkoExternal);
	const isVex = roles.includes(UserRole.Vex);
	const isServiceProvider = roles.includes(UserRole.ServiceProvider);
	const isApprover = roles.includes(UserRole.ShApprover);
	const isRegulationReader = roles.includes(UserRole.RegulationReader);
	const isBedarfskoordinator = roles.includes(UserRole.Bedarfskoordinator);
	const isReader =
		!isAdmin &&
		!isVko &&
		!isVkoExternal &&
		!isVex &&
		!isServiceProvider &&
		!isApprover &&
		!isRegulationReader &&
		!isBedarfskoordinator &&
		roles
			.map(role => role.toLowerCase())
			?.includes(UserRole.Readers.toLowerCase());

	const {data: vexClusterData} = useGetMyVexClustersQuery();
	const myVexClusters = React.useMemo(
		() => (vexClusterData?.myVexClusters || []) as VexCluster[],
		[vexClusterData],
	);

	const {data: keywordData} = useGetMyKeywordsQuery();
	const myKeywords = React.useMemo(
		() => (keywordData?.myKeywords ?? []) as Keyword[],
		[keywordData],
	);

	const {data: favoriteData, loading} = useMyFavoritesIdsOnlyQuery();
	const favoriteIds = React.useMemo(() => {
		const favorites = favoriteData?.myFavorites;
		if (favorites && !loading) {
			const favIds = {} as {[key: string]: boolean};
			favorites.regulations.forEach(ref => {
				favIds[ref.id] = true;
			});
			favorites.regulatoryDocuments.forEach(ref => {
				favIds[ref.id] = true;
			});
			favorites.paragraphs.forEach(ref => {
				favIds[ref.id] = true;
			});
			favorites.requirements.forEach(ref => {
				favIds[ref.id] = true;
			});

			return favIds;
		}
	}, [favoriteData, loading]);

	const {data: tableConfigurationData} = useMyTableConfigurationsQuery();
	const tableConfigurations = React.useMemo(
		() =>
			(tableConfigurationData?.myTableConfigurations ??
				[]) as TableConfiguration[],
		[tableConfigurationData],
	);

	return (
		<UserContext.Provider
			value={{
				...account,
				encodedUserId,
				roles,
				isAdmin,
				isVko,
				isVkoExternal,
				isVex,
				isServiceProvider,
				isApprover,
				isReader,
				isRegulationReader,
				isBedarfskoordinator,
				myVexClusters,
				myKeywords,
				favoriteIds,
				tableConfigurations,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

function encodeUserId(uuid: string): string {
	const str = 'User\nd' + uuid;
	return window.btoa(str);
}

export const useUserContext = () => React.useContext(UserContext);
