import {
	DefaultButton,
	FontIcon,
	Panel,
	PanelType,
	useTheme,
} from '@fluentui/react';
import React, {useCallback, useEffect} from 'react';
import {useParagraphsContext} from '../ParagraphsContext';
import {RegulatoryDocumentParagraph} from 'types';
import {
	ParagraphNode,
	useNestedParagraphs,
} from 'features/RegulatoryDocuments/utils';
import CheckboxTree, {OnCheckNode} from 'react-checkbox-tree';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {useTranslation} from 'react-i18next';
import {QueryParagraph} from 'features/RegulatoryDocuments/RegDocDetailsPage/RegDocDetailsPage.queryTypes';
import {ParagraphIds} from './ParagraphTableOfContents.types';
import {CheckedItemsService} from './ParagraphTableOfContents.checkedItems.service';

type ParagraphTableOfContentsProps = {
	isOpen: boolean;
	onDismiss: () => void;
	visibleParagraphs: RegulatoryDocumentParagraph[];
	allParagraphs: QueryParagraph[];
};

export const ParagraphTableOfContents: React.FC<
	ParagraphTableOfContentsProps
> = ({isOpen, onDismiss, visibleParagraphs, allParagraphs}) => {
	const theme = useTheme();
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'ParagraphTableOfContents',
	});
	const {setFilteredParagraphs} = useParagraphsContext();
	const {setScrollToParagraphId} = useParagraphsContext();

	interface CheckedParagraphsMap {
		[key: string]: boolean;
	}

	const [checked, setChecked] = React.useState<Array<string>>([]);
	const [expanded, setExpanded] = React.useState<Array<string>>([]);

	const filterParagraphsUsingFilters = useCallback(() => {
		const createCheckedParagraphsMap = (): CheckedParagraphsMap => {
			const newCheckedParagraphsMap: CheckedParagraphsMap = {};
			checked.forEach(id => {
				newCheckedParagraphsMap[id] = true;
			});
			return newCheckedParagraphsMap;
		};

		const updateFilteredParagraphs = (): void => {
			const checkedParagraphsMap: CheckedParagraphsMap =
				createCheckedParagraphsMap();
			setFilteredParagraphs(
				visibleParagraphs.filter(p => checkedParagraphsMap[p.id]),
			);
		};

		updateFilteredParagraphs();
	}, [checked, visibleParagraphs, setFilteredParagraphs]);

	useEffect(filterParagraphsUsingFilters, [filterParagraphsUsingFilters]);

	const treeItems: ParagraphNode[] = useNestedParagraphs(
		allParagraphs,
		visibleParagraphs,
	);

	const onClick = React.useCallback(
		(node: OnCheckNode) => {
			const paragraph = visibleParagraphs.find(p => p.id === node.value);
			if (paragraph) {
				handleCheckingAnItem([paragraph.id]);
			}
		},
		[visibleParagraphs, setScrollToParagraphId],
	);

	const handleCheckingAnItem = useCallback(
		(checkedParagraphIds: ParagraphIds) => {
			const checkedItems = CheckedItemsService.getCheckedItems({
				checkedItemsFromCheckHandler: checkedParagraphIds,
				checkedItemsFromState: checked,
				tree: treeItems,
			});
			setChecked(checkedItems);
		},
		[checked, treeItems],
	);

	const clearSelection = React.useCallback(() => {
		setChecked([]);
		setFilteredParagraphs(visibleParagraphs);
	}, [visibleParagraphs, setFilteredParagraphs]);

	const onRenderFooterContent = React.useCallback(
		() => (
			<DefaultButton
				text={t('ClearSelection')}
				onClick={clearSelection}
				disabled={checked.length === 0}
			/>
		),
		[checked, t, clearSelection],
	);

	const icons = React.useMemo(
		() => ({
			check: (
				<FontIcon
					iconName='CheckboxCompositeReversed'
					style={{color: theme.palette.themePrimary}}
				/>
			),
			uncheck: <FontIcon iconName='Checkbox' />,
			halfCheck: <FontIcon iconName='CheckboxComposite' />,
			expandClose: <FontIcon iconName='ChevronDown' />,
			expandOpen: <FontIcon iconName='ChevronUp' />,
			expandAll: (
				<FontIcon
					iconName='CaretDownSolid8'
					style={{color: theme.palette.themePrimary}}
					title={t('ExpandAll')}
				/>
			),
			collapseAll: (
				<FontIcon
					iconName='CaretUpSolid8'
					style={{color: theme.palette.themePrimary}}
					title={t('CollapseAll')}
				/>
			),
		}),
		[t, theme.palette.themePrimary],
	);

	/**
	 * We disable the default cascading behavior. Otherwise, if a user checked a
	 * checkbox that had disabled nested checkboxes, the parent checkbox would not
	 * appear to be checked. Note that we created our own cascading functionality.
	 */
	return (
		<Panel
			isOpen={isOpen}
			onDismiss={onDismiss}
			isBlocking={false}
			type={PanelType.medium}
			isLightDismiss={true}
			headerText={t('TableOfContents')}
			onRenderFooterContent={onRenderFooterContent}
		>
			<CheckboxTree
				showNodeIcon={false}
				showExpandAll={true}
				checkModel={'all'}
				nodes={treeItems}
				checked={checked}
				expanded={expanded}
				onCheck={handleCheckingAnItem}
				onExpand={expanded => setExpanded(expanded)}
				onClick={onClick}
				icons={icons}
				noCascade
			/>
		</Panel>
	);
};
