import {AttachmentRef} from 'types';
import i18n from 'i18n/i18n';
import {TFunction} from 'react-i18next';
import {formatDateTime} from 'i18n/localeDateFormat';
import Excel from 'exceljs';
import JSZip from 'jszip';
import {saveAs} from 'file-saver';
import {bImageXL} from './ExcelLogo';

export interface IArrayRoles {
	isAdmin: boolean;
	isVko: boolean;
	isVex: boolean;
	isReader: boolean;
	isBedarfskoordinator: boolean;
}

export interface IHTMLXL {
	font: IDictFont;
	text: string;
}

interface IDictFont {
	bold: boolean;
	underline?: boolean;
	italic?: boolean;
	strike?: boolean;
	vertAlign?: string | undefined;
	name: string;
	size: number;
}

interface IRenderHeaderXL {
	(
		workbook: Excel.Workbook,
		sheet: Excel.Worksheet,
		arrColWidth: number[],
		strPageName: string,
		t: TFunction,
	): void;
}

interface IRenderHTMLXL {
	(
		arrChildNodes: NodeListOf<ChildNode>,
		arrHTMLXL: IHTMLXL[],
		dictFont?: IDictFont | undefined,
	): void;
}

interface ICleanHTMLXL {
	(arrHTMLXL: IHTMLXL[], arrHTMLXLClean: IHTMLXL[]): void;
}

interface ICreateZIPXL {
	(
		workbook: Excel.Workbook,
		strZipName: string,
		t: TFunction,
		arrTables?: string[] | undefined,
		arrImagesUri?: string[] | undefined,
		arrImagesName?: string[] | undefined,
		arrAttachments?: AttachmentRef[] | undefined,
	): Promise<void>;
}

export const renderHeaderXL: IRenderHeaderXL = (
	workbook,
	sheet,
	arrColWidth,
	strPageName,
	t,
) => {
	workbook.properties.date1904 = true;
	workbook.calcProperties.fullCalcOnLoad = false;
	sheet.properties.outlineProperties = {
		summaryBelow: false,
		summaryRight: false,
	};

	arrColWidth.forEach((x, i) => {
		sheet.getColumn(i + 2).font = {name: 'Porsche Next TT', size: 10};
		sheet.getColumn(i + 2).width = x;
	});

	let intRowCount = 1;
	let row = sheet.getRow(intRowCount);

	const imageId2 = workbook.addImage({
		base64: bImageXL,
		extension: 'png',
	});

	sheet.addImage(imageId2, {
		tl: {col: 0, row: 0},
		ext: {width: 310, height: 60},
		editAs: 'oneCell',
		hyperlinks: {
			hyperlink: window.location.href,
			tooltip: 'TRACE',
		},
	});

	row.height = 70.0;
	row.fill = {
		type: 'pattern',
		pattern: 'solid',
		fgColor: {argb: '303030'},
	};

	intRowCount++;
	row = sheet.getRow(intRowCount);
	row.alignment = {wrapText: true, vertical: 'top'};
	row.getCell('B').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: strPageName,
			},
		],
	};

	row.getCell('E').value = {
		richText: [
			{
				font: {bold: true, name: 'Porsche Next TT', size: 10},
				text: t('ExcelDate', {ns: 'components/EntityList/ExcelExport'}) + ':\n',
			},
			{
				font: {bold: false, name: 'Porsche Next TT', size: 10},
				text: formatDateTime(new Date(), i18n),
			},
		],
	};
};

export const renderHTMLExcel: IRenderHTMLXL = (
	arrChildNodes,
	arrHTMLXL,
	dictFontParent,
) => {
	for (let ii = 0; ii < arrChildNodes.length; ii++) {
		const dictFont = {
			bold: dictFontParent
				? dictFontParent.bold
				: arrChildNodes[ii].nodeName === 'b'.toUpperCase() ||
				  arrChildNodes[ii].nodeName === 'strong'.toUpperCase() ||
				  arrChildNodes[ii].nodeName === 'h2'.toUpperCase(),
			underline: dictFontParent
				? dictFontParent.underline
				: arrChildNodes[ii].nodeName === 'i'.toUpperCase() ||
				  arrChildNodes[ii].nodeName === 'u'.toUpperCase(),
			italic: dictFontParent ? dictFontParent.italic : false,
			strike: dictFontParent ? dictFontParent.strike : false,
			name: dictFontParent ? dictFontParent.name : 'Porsche Next TT',
			size: arrChildNodes[ii].nodeName === 'h2'.toUpperCase() ? 12 : 10,
		};

		if (arrChildNodes[ii].nodeName === 'li'.toUpperCase()) {
			arrHTMLXL.push({font: dictFont, text: ' •'});
		}

		if (arrChildNodes[ii].nodeName === '#text') {
			const strText = arrChildNodes[ii].textContent ?? '\n';
			arrHTMLXL.push({font: dictFont, text: strText === '' ? '\n' : strText});
		}

		if (arrChildNodes[ii].childNodes.length > 0) {
			renderHTMLExcel(arrChildNodes[ii].childNodes, arrHTMLXL, dictFont);
		}

		if (arrChildNodes[ii].nodeName === 'p'.toUpperCase()) {
			arrHTMLXL.push({font: dictFont, text: '\n'});
		}

		if (arrChildNodes[ii].nodeName === 'br'.toUpperCase()) {
			arrHTMLXL.push({font: dictFont, text: '\n'});
		}

		if (arrChildNodes[ii].nodeName === 'li'.toUpperCase()) {
			arrHTMLXL.push({font: dictFont, text: '\n'});
		}
	}
};

export const cleanHTMLExcel: ICleanHTMLXL = (arrHTMLXL, arrHTMLXLClean) => {
	for (let jj = 0; jj < arrHTMLXL.length; jj++) {
		if (arrHTMLXL[jj].text === '\n' && jj > 1) {
			if (
				!(arrHTMLXL[jj - 1].text === '\n' && arrHTMLXL[jj - 2].text === '\n')
			) {
				arrHTMLXLClean.push(arrHTMLXL[jj]);
			}
		} else {
			arrHTMLXLClean.push(arrHTMLXL[jj]);
		}
	}
};

export const createZIPXL: ICreateZIPXL = async (
	workbook,
	strZipName,
	t,
	arrTables?,
	arrImagesUri?,
	arrImagesName?,
	arrAttachments?,
) => {
	const strDate = new Date()
		.toISOString()
		.replace('T', ' ')
		.slice(0, 10)
		.replaceAll('-', '');

	const buffer = await workbook.xlsx.writeBuffer();
	const blobXL = new Blob([buffer], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
	const blobMSG = new Blob([''], {type: 'text'});
	const zip = new JSZip();

	(arrTables ?? [])
		.map(strHTMLTable => new Blob([strHTMLTable], {type: 'text.txt'}))
		.map((blob, intID) =>
			zip.file('assets/html_table_' + String(intID) + '.html', blob),
		);

	(arrImagesUri ?? [])
		.map(strImageUri => {
			const strHTML = `<!DOCTYPE html><html><body><img src="${strImageUri}" ></body></html>`;
			return new Blob([strHTML], {type: 'text.txt'});
		})
		.map((blob, intID) =>
			zip.file('assets/' + String(arrImagesName![intID]), blob),
		);

	zip.file(
		strDate + '_' + i18n.language + '_TRACE_' + strZipName + '.xlsx',
		blobXL,
	);
	zip.file(
		t('ExcelExtract', {ns: 'components/EntityList/ExcelExport'}),
		blobMSG,
	);
	const objZip = await zip.generateAsync({type: 'blob'});
	const blobZip = new Blob([objZip], {type: 'application/zip'});
	const fileName =
		strDate + '_' + i18n.language + '_TRACE_EXPORT_' + strZipName + '.zip';
	saveAs(blobZip, fileName);
};
