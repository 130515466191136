import {
	DirectionalHint,
	Icon,
	IDetailsListProps,
	ITooltipProps,
	SelectionMode,
	TooltipDelay,
	TooltipHost,
} from '@fluentui/react';
import {useId} from '@fluentui/react-hooks';
import {EntityList, EntityListColumn, EntityListProps} from 'components';
import {
	renderAttachments,
	renderDocument,
	renderFaqs,
	renderReferenceField,
} from 'components/EntityList/ColumnRenderers';
import {PageRegulatoryDocs} from 'features/Regulations/RegulationDetail/RegulationDetail.types';
import {useCommand, useSelection} from 'hooks';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {RegulatoryDocument, UserRole} from 'types';
import {useRegulatoryDocumentsContext} from '../../context';
import {getRegulatoryDocumentsListBaseColumns} from '../DocumentDetails/RegulatoryDocumentsListBaseColumns';
import {REG_DOC_TRANSLATION_NAMESPACE} from '../regDocConstants';
import {SplitDocumentLegend} from '../SplitDocumentLegend';
import {RegDocsListDetailsRow} from './RegDocsList.DetailsRow';
import {RegDocsListDisabledDetailsRow} from './RegDocsList.DisabledDetailsRow';
import {RegDocsListRowRendererProps} from './RegDocsList.types';
import {SetRequired} from 'type-fest';
import {isApprovalModified} from 'features/RegulatoryDocuments/regulatoryUtils';
import {useRegulatoryDocAuthorization} from 'hooks/authorization/useRegulatoryDocAuthorization';
import {EntityContextProvider} from 'components/EntityPage/EntityContext';
import {useQuestionsData} from 'components/Faq/hooks/useQuestionsData';
import {RegulatoryDocumentsCollectionName} from 'components/Faq/EntityTypeMapper';
import {isDevEnvironment, isTestEnvironment} from 'appShell/Layout/Navigation';
import {FaqCommand} from 'components/Faq/FaqCommand';

type RegDocsListProps = Omit<
	EntityListProps,
	| 'columns'
	| 'items'
	| 'aria-rowcount'
	| 'onRenderRow'
	| 'selectionMode'
	| 'selection'
	| 'onItemInvoked'
> & {
	regulatoryDocuments: PageRegulatoryDocs;
	regulationId?: string;
	startPolling?: (pollInterval: number) => void;
	stopPolling?: () => void;
};

export const RegDocsList: React.FC<RegDocsListProps> = ({
	regulationId,
	regulatoryDocuments,
	startPolling,
	stopPolling,
	...entityLstProps
}) => {
	const {t} = useTranslation(REG_DOC_TRANSLATION_NAMESPACE, {
		keyPrefix: 'RegulatoryDocumentsList',
	});
	const {t: tEnum} = useTranslation('common/enums');
	const {
		filterAuthorizedRegulatoryDocuments,
		isRegulationRowDisabled,
		isViewParagraphsDisabled,
	} = useRegulatoryDocAuthorization();

	const [filteredDocuments, setFilteredDocuments] = useState<any>([]);

	const {
		selectedRegulatoryDocument,
		allSelectedRegulatoryDocuments,
		setSelectedRegulatoryDocument,
		setAllSelectedRegulatoryDocuments,
	} = useRegulatoryDocumentsContext();

	const regulatoryDocumentsData = useQuestionsData({
		data: regulatoryDocuments,
		entityType: RegulatoryDocumentsCollectionName,
		parentId: regulationId,
	});

	useEffect(() => {
		const elems = filterAuthorizedRegulatoryDocuments(
			regulatoryDocumentsData,
		).map((fd: any) => {
			return {
				...fd,
				workflow: {
					status:
						fd?.workflow?.status === 'MODIFIED' &&
						isApprovalModified(fd?.workflow?.reapprovingVKO?.id)
							? 'APPROVING_MODIFIED'
							: fd?.workflow?.status,
				},
			};
		});
		setFilteredDocuments(elems);
	}, [regulatoryDocumentsData, filterAuthorizedRegulatoryDocuments]);

	const onRenderRow: IDetailsListProps['onRenderRow'] = React.useCallback(
		(props: RegDocsListRowRendererProps) => {
			if (isRegulationRowDisabled(props?.item?.workflow?.status)) {
				return <RegDocsListDisabledDetailsRow propsFromRenderer={props} />;
			}

			return <RegDocsListDetailsRow propsFromRenderer={props} />;
		},
		[isRegulationRowDisabled],
	);

	type ListRegDoc = SetRequired<Partial<RegulatoryDocument>, 'id'>;

	const getKey = useCallback(
		(item: ListRegDoc): ListRegDoc['id'] => item.id,
		[],
	);

	const [selection] = useSelection<ListRegDoc>({
		onSelectionChanged() {
			setSelectedRegulatoryDocument(
				selection.getSelection()[0] as Partial<RegulatoryDocument>,
			);
			setAllSelectedRegulatoryDocuments(
				selection.getSelection() as Partial<RegulatoryDocument>[],
			);

			if (selection.count > 0) {
				stopPolling?.();
			} else {
				startPolling?.(10000);
			}
		},
		getKey,
	});

	const onRenderDocumentHeader = () => <DocumentHeader />;

	const columns = React.useMemo(() => {
		const baseColumns = [
			{
				key: 'document',
				isIconOnly: true,
				fieldName: 'documentStatus',
				minWidth: 16,
				maxWidth: 16,
				onRenderHeader: onRenderDocumentHeader,
				onRender: renderDocument(),
			},
			{
				name: 'attachments',
				key: 'attachments',
				isIconOnly: true,
				iconName: 'Attach',
				fieldName: 'attachments',
				minWidth: 16,
				maxWidth: 16,
				onRender: renderAttachments(),
			},
			...getRegulatoryDocumentsListBaseColumns(t, tEnum, true),
			{
				key: 'references',
				name: t('References'),
				iconName: 'Link',
				isIconOnly: true,
				fieldName: 'documentReferences',
				minWidth: 300,
				onRender: renderReferenceField(),
			},
		];
		if (isDevEnvironment() || isTestEnvironment())
			return baseColumns.concat({
				key: 'faq',
				name: 'FAQs',
				fieldName: 'faq',
				isMultiline: true,
				minWidth: 400,
				isResizable: true,
				filterable: true,
				onRender: renderFaqs(),
			}) as EntityListColumn[];

		return baseColumns as EntityListColumn[];
	}, [t, tEnum]);

	const navigate = useNavigate();

	const onItemInvoked = React.useCallback(
		(item: RegulatoryDocument) => {
			if (isRegulationRowDisabled(item.workflow?.status)) return;

			const regId = regulationId ?? item.regulation?.id;
			navigate(`/regulations/${regId}/${item?.id ?? ''}/paragraphs`);
		},
		[navigate, isRegulationRowDisabled, regulationId],
	);

	useCommand(
		{
			key: 'viewParagraphs',
			text: t('ViewParagraphs'),
			priority: 6,
			iconProps: {iconName: 'ThumbnailView'},
			onClick() {
				if (selectedRegulatoryDocument?.id) {
					navigate(`${selectedRegulatoryDocument?.id}/paragraphs`);
				}
			},
			disabled:
				!selectedRegulatoryDocument ||
				allSelectedRegulatoryDocuments?.length !== 1 ||
				isViewParagraphsDisabled(selectedRegulatoryDocument),
			roles: [
				UserRole.SystemAdministrator,
				UserRole.Vko,
				UserRole.Vex,
				UserRole.ShApprover,
				UserRole.Readers,
				UserRole.RegulationReader,
				UserRole.Bedarfskoordinator,
			],
		},
		[selectedRegulatoryDocument, navigate, allSelectedRegulatoryDocuments],
	);

	return (
		<div>
			<EntityContextProvider>
				<EntityList
					{...entityLstProps}
					items={filteredDocuments}
					columns={columns}
					aria-rowcount
					onRenderRow={onRenderRow}
					selectionMode={SelectionMode.multiple}
					selection={selection}
					onItemInvoked={onItemInvoked}
					getKey={getKey}
				/>
				<FaqCommand />
			</EntityContextProvider>
		</div>
	);
};

const documentTooltipProps: ITooltipProps = {
	onRenderContent: () => <SplitDocumentLegend />,
};

const DocumentHeader = () => {
	const documentTooltipId = useId('documentTooltip');

	return (
		<TooltipHost
			tooltipProps={documentTooltipProps}
			delay={TooltipDelay.zero}
			id={documentTooltipId}
			directionalHint={DirectionalHint.leftCenter}
		>
			<Icon iconName='Page' />
		</TooltipHost>
	);
};
